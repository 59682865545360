const convertTextToPath = (svgElement) => {
  const texts = svgElement.querySelectorAll('text');
  texts.forEach(text => {
    // Get the bounding box of the text
    const bbox = text.getBBox();
    // Create a temporary canvas to measure and render the text
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Get computed style of the text element
    const style = window.getComputedStyle(text);
    const fontSize = parseFloat(style.fontSize);
    const fontFamily = style.fontFamily;
    const fontWeight = style.fontWeight;
    const fontStyle = style.fontStyle;
    
    // Set the font on canvas context
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
    
    // Create SVG path for the text
    const textContent = text.textContent;
    const x = parseFloat(text.getAttribute('x') || 0);
    const y = parseFloat(text.getAttribute('y') || 0);
    
    // Create path element
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    
    // Copy relevant attributes from text to path
    const attributes = ['fill', 'stroke', 'stroke-width', 'opacity', 'transform'];
    attributes.forEach(attr => {
      const value = text.getAttribute(attr);
      if (value) path.setAttribute(attr, value);
    });
    
    // Copy computed styles
    path.style.fill = style.fill;
    path.style.stroke = style.stroke;
    path.style.strokeWidth = style.strokeWidth;
    path.style.opacity = style.opacity;
    
    try {
      // Convert text to path data
      const pathData = text.getPathData ? text.getPathData() : null;
      if (pathData) {
        path.setAttribute('d', pathData);
        // Replace text with path
        text.parentNode.replaceChild(path, text);
      }
    } catch (e) {
      console.warn('Could not convert text to path:', e);
    }
  });
  
  return svgElement;
};

const exportAsPNG = async (svgElement, filename = 'fretboard.png') => {
  if (!svgElement) {
    throw new Error('SVG element not found');
  }

  try {
    // Wait for next frame to ensure SVG is fully rendered
    await new Promise(resolve => requestAnimationFrame(resolve));

    // Get the SVG dimensions and viewBox
    const svgRect = svgElement.getBoundingClientRect();
    const viewBox = svgElement.getAttribute('viewBox');
    
    if (!viewBox) {
      throw new Error('SVG viewBox not found');
    }

    // Create a copy of the SVG with higher resolution (8x)
    const svgClone = svgElement.cloneNode(true);
    const scale = 8;
    const width = svgRect.width * scale;
    const height = svgRect.height * scale;
    
    // Set the dimensions on the clone
    svgClone.setAttribute('width', width);
    svgClone.setAttribute('height', height);
    svgClone.setAttribute('viewBox', viewBox);
    
    // Add xmlns attribute to ensure proper SVG namespace
    svgClone.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    
    // Copy computed styles from the original SVG elements to the clone
    const elements = svgClone.querySelectorAll('*');
    const originalElements = svgElement.querySelectorAll('*');
    
    elements.forEach((el, index) => {
      const originalEl = originalElements[index];
      if (!originalEl) return;

      const computedStyle = window.getComputedStyle(originalEl);
      
      // Copy essential styles
      const stylesToCopy = [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'fontStyle',
        'fill',
        'stroke',
        'strokeWidth',
        'opacity',
        'transform'
      ];

      stylesToCopy.forEach(style => {
        if (computedStyle[style]) {
          el.style[style] = computedStyle[style];
        }
      });
      
      // Handle colors
      if (el.getAttribute('fill') === '#111') {
        el.setAttribute('fill', 'none');
      }
      
      // Convert dark theme colors to light theme
      ['fill', 'stroke'].forEach(attr => {
        const value = el.getAttribute(attr);
        if (value === '#ccc' || value === '#aaa') {
          el.setAttribute(attr, '#000');
        }
      });
    });
    
    // Convert SVG to base64
    const svgData = new XMLSerializer().serializeToString(svgClone);
    const svgBase64 = btoa(unescape(encodeURIComponent(svgData)));
    const base64Url = `data:image/svg+xml;base64,${svgBase64}`;
    
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, width, height);
        
        // Draw with white background for better visibility
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, width, height);
        ctx.drawImage(img, 0, 0, width, height);
        
        try {
          const link = document.createElement('a');
          link.download = filename;
          link.href = canvas.toDataURL('image/png');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve('PNG exported successfully');
        } catch (err) {
          reject(`Failed to export PNG: ${err.message}`);
        }
      };
      
      img.onerror = (err) => reject(`Failed to load image: ${err.message}`);
      img.src = base64Url;
    });
  } catch (err) {
    throw new Error(`Export failed: ${err.message}`);
  }
};

const exportAsSVG = (svgElement, filename = 'fretboard.svg') => {
  if (!svgElement) {
    throw new Error('SVG element not found');
  }

  try {
    // Create a copy of the SVG
    const svgClone = svgElement.cloneNode(true);
    
    // Add xmlns attribute to ensure proper SVG namespace
    svgClone.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    
    // Copy computed styles from the original SVG elements to the clone
    const elements = svgClone.querySelectorAll('*');
    const originalElements = svgElement.querySelectorAll('*');
    
    elements.forEach((el, index) => {
      const originalEl = originalElements[index];
      if (!originalEl) return;

      const computedStyle = window.getComputedStyle(originalEl);
      
      // Copy essential styles
      const stylesToCopy = [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'fontStyle',
        'fill',
        'stroke',
        'strokeWidth',
        'opacity',
        'transform'
      ];

      stylesToCopy.forEach(style => {
        if (computedStyle[style]) {
          el.style[style] = computedStyle[style];
        }
      });
      
      // Handle colors
      if (el.getAttribute('fill') === '#111') {
        el.setAttribute('fill', 'none');
      }
    });
    
    // Convert SVG to string
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgClone);
    
    // Create blob and download
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    return 'SVG exported successfully';
  } catch (err) {
    throw new Error(`Export failed: ${err.message}`);
  }
};

export { exportAsPNG, exportAsSVG }; 