import React, { useState, useEffect, useRef, forwardRef, useLayoutEffect, useCallback } from 'react';
import { darkTheme, lightTheme } from './Themes';
import { NOTES, NOTE_TO_INDEX, SCALES, isNoteInScale, MAJOR_MODES, MELODIC_MINOR_MODES, HARMONIC_MINOR_MODES, HARMONIC_MAJOR_MODES, PENTATONIC_MODES, SCALE_DISPLAY_MODES, getChordQuality } from './TheoryControls';
import { NOTE_COLORS } from './TheoryControls';
import { INTERVAL_NAMES } from './TheoryControls';
import DiagramSVG from './DiagramSVG';

// Utility function to clamp a value between min and max
const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const MIN_DENSITY = 1;
const MAX_DENSITY = 25;

// Add this helper function
const getNoteColor = (note, colorMode = 'Default', theme, scaleRoot, nodeDisplayMode, nodeTextMode) => {
  if (!note) return null;
  
  const noteIndex = NOTE_TO_INDEX[note];
  if (noteIndex === undefined) return null;
  
  // Only make root note red if we're not in interval mode with hidden text
  if (note === scaleRoot && 
      !(nodeDisplayMode === 'intervals' && nodeTextMode === NODE_TEXT_MODES.HIDE_ALL)) {
    return '#FF0000'; // Red for root note
  }
  
  if (colorMode === 'Piano') {
    // Check if the note is an accidental
    const isAccidental = Array.isArray(NOTES[noteIndex]);
    return isAccidental ? theme.diagramBg : theme.nodeColor;
  }
  
  // For all other modes (Default and Rainbow), use the theme's node color
  return theme.nodeColor;
};

// Move formatNoteSymbol outside the component and export it
export const formatNoteSymbol = (note, accidentalStyle = 'sharp', key = null, scale = null) => {
  if (!note) return '';
  
  // Strip octave before processing
  note = stripOctave(note);
  
  // Check if the note has an accidental (is an array in NOTES)
  const isAccidental = Array.isArray(NOTES[NOTE_TO_INDEX[note]]);
  
  // Handle enharmonic spellings for specific keys, but only in Major scale
  if (key === 'Gb' && scale === 'Major') {
    if (accidentalStyle === 'flat') {
      // In Gb major with flats, B should be Cb
      if (note === 'B') return 'C♭';
    } else if (accidentalStyle === 'sharp') {
      // In Gb major with sharps, F should be E#
      if (note === 'F') return 'E♯';
    }
  }
  
  // Format normally
  return note
    .replace('#', '♯')
    .replace('b', '♭')
    .replace('♭', '♭');
};

// Add this helper function
const getNoteAtFret = (rootNote, fret, useFlats = false) => {
  const rootIndex = NOTE_TO_INDEX[stripOctave(rootNote)]; // Strip octave from root note
  if (rootIndex === undefined) return '';
  
  const noteIndex = (rootIndex + fret) % 12;
  const note = NOTES[noteIndex];
  
  // If the note has both sharp and flat variants, select based on preference
  if (Array.isArray(note)) {
    return note[useFlats ? 1 : 0];  // Use 0 for sharp (#), 1 for flat (b)
  }
  
  return note;
};

// Add this near the top of the file with other constants
export const NODE_TEXT_MODES = {
  SHOW_ALL: 'show_all',
  HIDE_ALL: 'hide_all'
};

// Add this helper function near the top with other utility functions
const getIntervalFromRoot = (root, note) => {
  if (!root || !note) return null;
  const rootIndex = NOTE_TO_INDEX[root];
  const noteIndex = NOTE_TO_INDEX[note];
  if (rootIndex === undefined || noteIndex === undefined) return null;
  
  const interval = (noteIndex - rootIndex + 12) % 12;
  return INTERVAL_NAMES[interval];
};

// Add this helper function to get interval index
const getIntervalIndex = (note, root) => {
  if (!note || !root) return null;
  const rootIndex = NOTE_TO_INDEX[root];
  const noteIndex = NOTE_TO_INDEX[note];
  if (rootIndex === undefined || noteIndex === undefined) return null;
  return (noteIndex - rootIndex + 12) % 12;
};

// Add this helper function near the top with other utility functions
const getDisplayIntervalName = (intervalIndex, activeIntervals) => {
  let displayName = INTERVAL_NAMES[intervalIndex];
  
  // Handle #4 case
  if (intervalIndex === 6) {
    const hasInterval7 = activeIntervals.has(7);
    const hasInterval5 = activeIntervals.has(5);
    const hasInterval8 = activeIntervals.has(8);
    const hasInterval9 = activeIntervals.has(9);
    
    // New condition: If interval 8 is being displayed as #5, always show this as #4
    if (hasInterval8 && !hasInterval7 && hasInterval9) {
      displayName = '♯4';
    } else if (hasInterval7 && !hasInterval5) {
      // Original condition remains as fallback
      displayName = '♯4';
    }
  }
  
  // Handle #5 case
  if (intervalIndex === 8) {
    const hasInterval7 = activeIntervals.has(7);
    const hasInterval9 = activeIntervals.has(9);
    
    if (!hasInterval7 && hasInterval9) {
      displayName = '♯5';
    }
  }
  
  return displayName;
};

// Add this helper function near the top of the file, before the Diagram component definition
const getItemsBetween = (start, end, max) => {
  const items = new Set();
  const step = end >= start ? 1 : -1;
  for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
    if (i >= 0 && i <= max) {
      items.add(i);
    }
  }
  return items;
};

// Add this helper function near other utility functions
const stripOctave = (note) => {
  if (!note) return '';
  // Remove any digit from the end of the note
  return note.replace(/\d+$/, '');
};

// Add useFlats parameter to the helper function
const getNoteWithOctave = (rootNote, fret, useFlats = false) => {
  if (!rootNote) return '';

  const match = rootNote.match(/([A-G][#b]?)(\d+)/);
  if (!match) return '';

  const [, baseNote, octaveStr] = match;
  const startingOctave = parseInt(octaveStr, 10);
  const rootIndex = NOTE_TO_INDEX[baseNote];
  if (rootIndex === undefined) return '';

  // If rootIndex ≥ 3 (C), then the given startingOctave is actually one octave higher
  // relative to the last C passed. If rootIndex < 3, we are before the C boundary in that octave.
  // Adjust baselineOctave accordingly:
  const baselineOctave = rootIndex >= 3 ? startingOctave - 1 : startingOctave;

  // Calculate total steps from the root note
  const totalSteps = rootIndex + fret;
  // How many full 12-semitone cycles (octaves) we've moved
  const octaveCount = Math.floor(totalSteps / 12);
  // The new note's index in the 12-semitone cycle
  const newIndex = totalSteps % 12;

  // The final octave increments by 1 if we've moved into or past index 3 (C)
  const finalOctave = baselineOctave + octaveCount + (newIndex >= 3 ? 1 : 0);

  const newNote = NOTES[newIndex];
  const noteToUse = Array.isArray(newNote)
    ? (useFlats ? newNote[1] : newNote[0])
    : newNote;

  return `${noteToUse}${finalOctave}`;
};

const getTunedNote = (startingNote, dragDistance, useFlats = false) => {
  // Extract the base note and octave
  const match = startingNote.match(/([A-G][#b]?)(\d+)/);
  if (!match) return startingNote;
  
  const [, baseNote, octaveStr] = match;
  const startingOctave = parseInt(octaveStr, 10);
  
  // Get the index of the starting note
  const startIndex = NOTE_TO_INDEX[baseNote];
  if (startIndex === undefined) return startingNote;
  
  // Calculate how many semitones to move
  // INVERTED: Positive dragDistance moves down (increases pitch), negative moves up (decreases pitch)
  const semitoneShift = Math.round(dragDistance / 10); // Adjust sensitivity as needed
  
  // Calculate the new note index
  let newIndex = startIndex + semitoneShift; // Add because dragging down should increase pitch
  
  // Calculate octave shifts
  let octaveShift = Math.floor(Math.abs(newIndex) / 12) * Math.sign(semitoneShift);
  newIndex = ((newIndex % 12) + 12) % 12; // Ensure positive index
  
  // Get the new note
  const newNote = NOTES[newIndex];
  const noteToUse = Array.isArray(newNote) ? (useFlats ? newNote[1] : newNote[0]) : newNote;
  
  // Calculate the new octave
  // Only change octave when crossing between B and C
  let newOctave = startingOctave;
  
  if (semitoneShift > 0) {
    // Moving down (pitch up)
    if (startIndex < 3 && newIndex >= 3) { // Crossed C going up in pitch
      newOctave++;
    }
  } else if (semitoneShift < 0) {
    // Moving up (pitch down)
    if (startIndex >= 3 && newIndex < 3) { // Crossed B going down in pitch
      newOctave--;
    }
  }
  
  // Apply the octave shift
  newOctave += octaveShift;
  
  return `${noteToUse}${newOctave}`;
};

const Diagram = forwardRef(({
  svgRef: propsSvgRef,  // Rename the prop to avoid confusion
  unit,
  strings = 6,
  showNodes = false,
  height,
  isDarkMode,
  stringSizeBase = 1,
  isInfiniteFrets,
  showInlays = false,
  isVertical = false,
  onViewboxChange,
  fretDensity = 3.5,
  onFretDensityChange,
  isInverted = false,
  tuning = ['E', 'A', 'D', 'G', 'B', 'E'],
  useFlats = false,
  accidentalStyle = 'sharp',
  showBounds = false,
  titleAreaRef,
  selectedKey = null,
  selectedScale = null,
  onNodeClick,
  noteColors = {},
  scaleRoot = null,
  rootIndicator = 'circle',
  colorMode = 'Default',
  currentPreset = null,
  nodeTextMode = NODE_TEXT_MODES.SHOW_ALL,
  nodeDisplayMode,
  scaleDisplayMode = SCALE_DISPLAY_MODES.FULL,
  selectedInterval = 0,
  onStringSelect,
  clickMode = 'root',
  onPlayNote,
  onInlayToggle,
  setScaleRoot,
  onNodeDisplayModeChange,
  isAudioInitialized,
  isTuningMode = false,
  onTuningChange, // Add this prop
  activeTuningString,
  setActiveTuningString,
  PADDING,
  isFullscreen,
  hiddenNodes,
  setHiddenNodes,
  selectedStrings,
  setSelectedStrings,
  selectedFrets,
  setSelectedFrets,
}, ref) => {

  // Create a combined ref that uses either the forwarded ref or props ref
  const combinedRef = useCallback(node => {
    // Update both refs
    if (typeof ref === 'function') ref(node);
    else if (ref) ref.current = node;
    if (propsSvgRef) propsSvgRef.current = node;
  }, [ref, propsSvgRef]);

  const containerRef = useRef(null);
  const nodeRadius = unit * 0.75;

  const isFretless = ["Violin", "Viola", "Cello"].includes(currentPreset?.instrument);

  // Define fret widths
  const regularFretWidth = isFretless ? stringSizeBase : stringSizeBase * 4;
  const fret0Width = isFretless ? regularFretWidth * 12 : regularFretWidth * 3; // Make fret 0 three times wider

  // Move these calculations here so they can be reused
  const bottomStringThickness = stringSizeBase + ((strings - 1) * 1); // Thickest string
  const stringPadding = (bottomStringThickness * 0.5);

  // Update calculateDimensions to use the moved calculations
  const calculateDimensions = (containerWidth, stringCount, unitSize, baseStringSize, isVertical) => {
    const stringSpacing = unitSize * 0.75 * 3; // nodeRadius * 3
    const totalStringHeight = isVertical 
      ? stringSpacing * (stringCount - 1) + (unitSize * 3)
      : stringSpacing * (stringCount - 1);
    
    return {
      width: containerWidth,
      height: totalStringHeight + stringPadding,
    };
  };

  // Initial dimensions state
  const [dimensions, setDimensions] = useState(() => 
    calculateDimensions(window.innerWidth, strings, unit, stringSizeBase, isVertical)
  );

  // Update stringLinePositions calculation
  const stringLinePositions = Array.from({ length: strings }, (_, i) => {
    const stringSpacing = nodeRadius * 3;
    
    if (strings === 1) {
      return dimensions.height / 2;
    } else {
      // Calculate base position
      const basePosition = i * stringSpacing;
      
      // Calculate string thickness based on position
      const stringThickness = stringSizeBase + (isInverted ? i : (strings - 1 - i)) * 1;
      
      // In vertical mode, first offset all strings left by half of thickest string thickness,
      // then adjust individual positions based on their own thickness
      if (isVertical) {
        const thickestStringOffset = bottomStringThickness / 2;
        
        if (isInverted) {
          // When inverted, thickest string (last) moves right, thinnest (first) moves left
          return basePosition + thickestStringOffset - (stringThickness / 2);
        } else {
          // When not inverted, thickest string (first) moves right, thinnest (last) moves left
          return basePosition - thickestStringOffset + (stringThickness / 2);
        }
      }
      
      // For horizontal mode, maintain existing behavior
      if (isInverted) {
        return basePosition + bottomStringThickness / 2;
      }
      
      return basePosition;
    }
  });

  const [lines, setLines] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const lastMouseX = useRef(0);
  const [targetOffset, setTargetOffset] = useState(0);
  const animationFrameRef = useRef(null);
  const [intersections, setIntersections] = useState([]);

  const theme = isDarkMode ? darkTheme : lightTheme;

  // Add state for fretDensity
  const [currentFretDensity, setCurrentFretDensity] = useState(fretDensity);

  const [nodeTransform, setNodeTransform] = useState({ scale: 1 });
  const [clickedNode, setClickedNode] = useState(null);

  // Add state for pinch-zoom
  const [isPinching, setIsPinching] = useState(false);
  const [lastPinchDistance, setLastPinchDistance] = useState(null);

  // Add this constant at the top of the component
  const MIN_VISIBLE_FRETS = 3;  // Minimum number of frets to show


  // Calculate horizontal line positions
  const getLineWidth = (index, totalLines) => {
    // Special case for 5-string banjo
    if (currentPreset?.instrument === "Banjo" && currentPreset?.strings === 5) {
      // Make the 5th string (index 4) the same width as the thinnest string
      if (index === 4) {
        return stringSizeBase + (isInverted ? (totalLines - 1) : 0) * 1;
      }
    }
    
    // Default behavior for all other cases
    const adjustedIndex = isInverted ? (totalLines - 1 - index) : index;
    return stringSizeBase + adjustedIndex * 1;
  };

  // Update the useEffect that handles dimension updates
  useLayoutEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const newDimensions = calculateDimensions(rect.width, strings, unit, stringSizeBase, isVertical);

        // Only update if dimensions actually changed
        if (
          Math.abs(newDimensions.width - dimensions.width) > 1 ||
          Math.abs(newDimensions.height - dimensions.height) > 1
        ) {
          setDimensions(newDimensions);
          // Move density check here
          const testLines = generateFrets(currentOffset);
          if (!isValidDensityIncrease(testLines, nodeRadius)) {
            // Find a valid density by incrementing until we find one that works
            let validDensity = currentFretDensity;
            while (validDensity < MAX_DENSITY) {
              validDensity += 0.5; // Increment by 0.5 and test again
              const newTestLines = generateFrets(currentOffset, validDensity);
              if (isValidDensityIncrease(newTestLines, nodeRadius)) {
                setCurrentFretDensity(validDensity);
                onFretDensityChange?.(validDensity);
                break;
              }
            }
          }
        }

        onViewboxChange?.({ unit });
      }
    };

    updateDimensions();

    const resizeObserver = new ResizeObserver(updateDimensions);
    window.addEventListener('resize', updateDimensions);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, [unit, strings, stringSizeBase, onViewboxChange, isVertical]); // Remove dimensions from dependencies

  // Clamp scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Replace the getMaxScroll and related parts of generateFrets
  const calculateVisibleFrets = (density = currentFretDensity) => {
    // Use the dimension in the direction of the fretboard
    const availableSpace = isVertical 
      ? dimensions.height
      : dimensions.width;

    const numFrets = Math.max(
      MIN_VISIBLE_FRETS,
      Math.ceil(availableSpace / (unit * density))
    );

    // Always limit to 24 visible frets maximum, regardless of mode
    return Math.min(numFrets, 24);
  };

  const getMaxScroll = () => {
    if (isInfiniteFrets) {
      return null;
    }
    const visibleFrets = calculateVisibleFrets();
    return Math.max(0, 24 - visibleFrets);
  };

  // Update generateFrets to use proper dimensions
  const generateFrets = (offset, density = currentFretDensity) => {
    const positions = [];
    const numLines = calculateVisibleFrets(density);
    
    // Calculate border widths
    const isFret0Border = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
    const nutWidth = isFret0Border ? fret0Width : regularFretWidth;
    const bridgeWidth = regularFretWidth;
    
    // Subtract half of both border widths from available space
    const availableSpace = isVertical 
      ? dimensions.height - (nutWidth / 2) - (bridgeWidth / 2)
      : dimensions.width - (nutWidth / 2) - (bridgeWidth / 2);

    // Add extra spacing for fret 0 nodes only when nodes are visible
    const isFret0Visible = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
    const extraNutSpacing = isFret0Visible && showNodes ? nodeRadius * 2 : 0;

    const FRET_CONSTANT = Math.pow(2, 1/12);
    const totalFrets = numLines;
    const maxScaleFactor = Math.pow(FRET_CONSTANT, -totalFrets);

    positions.length = 0;
    // Start at half the nut width
    positions.push(nutWidth / 2 + extraNutSpacing);

    const isFret0Transitioning = !isInfiniteFrets && scrollPosition < 1;

    if (isFret0Transitioning) {
      const transitionProgress = 1 - scrollPosition;
      const currentFret0Width = showNodes 
        ? (fret0Width * transitionProgress + regularFretWidth * (1 - transitionProgress))
        : regularFretWidth;
      
      const fret0ReferencePoint = regularFretWidth + extraNutSpacing;
      
      for (let i = 1; i <= totalFrets; i++) {
        const fretPosition = i - offset;
        const scaleFactor = Math.pow(FRET_CONSTANT, -fretPosition);
        const normalizedScale = 1 - (scaleFactor - 1) / (maxScaleFactor - 1);
        const x = fret0ReferencePoint + (availableSpace - fret0ReferencePoint) * (1 - normalizedScale);

        if (x >= 0 && x <= availableSpace) {
          positions.push(x + nutWidth / 2); // Add half nut width to all positions
        }
      }
    } else {
      for (let i = 0; i <= totalFrets; i++) {
        const fretPosition = i - offset;
        const scaleFactor = Math.pow(FRET_CONSTANT, -fretPosition);
        const normalizedScale = 1 - (scaleFactor - 1) / (maxScaleFactor - 1);
        const x = extraNutSpacing + (availableSpace - extraNutSpacing) * (1 - normalizedScale);

        if (x >= 0 && x <= availableSpace) {
          positions.push(x + nutWidth / 2); // Add half nut width to all positions
        }
      }
    }

    // Add the final position (total width including border widths)
    if (positions[positions.length - 1] !== availableSpace + nutWidth / 2) {
      positions.push(availableSpace + nutWidth / 2);
    }

    return [...new Set(positions)].sort((a, b) => a - b);
  };

  // Update the mode changes effect
  useLayoutEffect(() => {
    // Reset all position-related state when switching modes
    setScrollPosition(0);
    setCurrentOffset(0);
    setTargetOffset(0);

    // Force recalculation of lines and fret numbers
    // Wait for next frame to ensure dimensions are updated
    requestAnimationFrame(() => {
      const numFrets = calculateVisibleFrets();
      const initialFrets = Array.from({ length: numFrets }, (_, i) => i);
      setVisibleFrets(initialFrets);
      
      const newLines = generateFrets(0);
      setLines(newLines);
    });
  }, [isVertical, dimensions.width, dimensions.height]);

  // Add these new states near the top with other state declarations
  const [dragStartPosition, setDragStartPosition] = useState(null);
  const [hasGenuinelyDragged, setHasGenuinelyDragged] = useState(false);
  const DRAG_THRESHOLD = 5; // pixels

  // Update handleMouseDown
  const handleMouseDown = (e) => {
    if (isTuningMode) {
      // Don't prevent default dragging in tuning mode
      setIsDragging(true);
      lastMouseX.current = isVertical ? e.clientY : e.clientX;
      setDragStartPosition({
        x: e.clientX,
        y: e.clientY
      });
      setHasGenuinelyDragged(false);
      return;
    }
    
    setIsDragging(true);
    lastMouseX.current = isVertical ? e.clientY : e.clientX;
    setDragStartPosition({
      x: e.clientX,
      y: e.clientY
    });
    setHasGenuinelyDragged(false);
  };

  // Add these state variables at the top of the component
  const [visibleFrets, setVisibleFrets] = useState([]);

  // Add a new state to track if we're currently snapping
  const [isSnapping, setIsSnapping] = useState(false);

  // Add this state near other state declarations
  const [tuningDragStart, setTuningDragStart] = useState(null);

  // Update handleMouseMove
  const handleMouseMove = (e) => {
    if (!isDragging) return;

    // Check if we've exceeded the drag threshold
    if (dragStartPosition && !hasGenuinelyDragged) {
      const deltaX = Math.abs(e.clientX - dragStartPosition.x);
      const deltaY = Math.abs(e.clientY - dragStartPosition.y);
      if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
        setHasGenuinelyDragged(true);
      }
    }

    // Add tuning mode handling with orientation-aware dragging and reduced sensitivity
    if (isTuningMode && activeTuningString !== null) {
      // Initialize drag start position if not set
      if (!tuningDragStart) {
        setTuningDragStart({
          x: e.clientX,
          y: e.clientY,
          initialNote: tuning[isInverted ? activeTuningString : tuning.length - 1 - activeTuningString]
        });
        return;
      }
      
      // Calculate cumulative movement based on orientation with reduced sensitivity
      const dragDistance = (isVertical
        ? tuningDragStart.y - e.clientY  // Use vertical movement in vertical mode
        : tuningDragStart.x - e.clientX) * 0.5;  // Use horizontal movement in horizontal mode, reduced by half
      
      // Get the correct tuning index based on inversion
      const tuningIndex = isInverted 
        ? activeTuningString 
        : tuning.length - 1 - activeTuningString;
      
      // Use the initial note as reference for the change
      const newNote = getTunedNote(tuningDragStart.initialNote, dragDistance, useFlats);
      
      // Update tuning if changed
      if (newNote !== tuning[tuningIndex]) {
        const newTuning = [...tuning];
        newTuning[tuningIndex] = newNote;
        onTuningChange?.(newTuning);
      }
      return;
    }

    // Prevent scrolling in tuning mode or when dragging frets
    if (isTuningMode || isDraggingFrets) return;

    // Original scroll behavior
    const delta = isVertical ? e.clientY - lastMouseX.current : e.clientX - lastMouseX.current;
    const sensitivity = 0.02;

    let newScrollPosition = scrollPosition - delta * sensitivity;
    
    if (!isInfiniteFrets) {
      const maxScroll = getMaxScroll();
      newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
    }

    setScrollPosition(newScrollPosition);
    setCurrentOffset(newScrollPosition % 1);
    setTargetOffset(newScrollPosition % 1);

    lastMouseX.current = isVertical ? e.clientY : e.clientX;
  };

  // Modify handleMouseUp to remove clamping in infinite mode
  const handleMouseUp = () => {
    endLongPress();
    setActiveTuningString(null);
    setTuningDragStart(null);

    if (!isDragging) return;
    setIsDragging(false);
    setDragStartPosition(null);
    setIsSnapping(true);

    const nearestFret = Math.round(scrollPosition);
    
    if (!isInfiniteFrets) {
      const maxScroll = getMaxScroll();
      const limitedFret = clamp(nearestFret, 0, maxScroll);
      
      // Calculate the number of visible frets before updating position
      const numFrets = calculateVisibleFrets();
      const newVisibleFrets = Array.from(
        { length: numFrets + 1 }, // Add +1 to ensure we include the last fret during transition
        (_, i) => i + limitedFret
      ).filter(fret => fret >= 0 && fret <= 24);
      
      setVisibleFrets(newVisibleFrets);
      setScrollPosition(limitedFret);
    } else {
      setScrollPosition(nearestFret);
    }
    
    setTargetOffset(0);
    
    // Reset active tuning string
    setActiveTuningString(null);
  };

  // Add this helper function at the top level of the component
  const getShortestDistance = (current, target) => {
    const direct = target - current;
    const wrapped = direct > 0 ? direct - 1 : direct + 1;
    return Math.abs(direct) < Math.abs(wrapped) ? direct : wrapped;
  };

  // Update the animation effect
  useEffect(() => {
    if (targetOffset === currentOffset) {
      setIsSnapping(false); // Animation complete
      return;
    }

    const animate = () => {
      setCurrentOffset((current) => {
        const diff = getShortestDistance(current, targetOffset);
        const newOffset = current + diff * 0.1;

        if (Math.abs(diff) < 0.001) {
          setIsSnapping(false); // Animation complete
          return targetOffset;
        }

        return newOffset >= 1
          ? newOffset - 1
          : newOffset < 0
          ? newOffset + 1
          : newOffset;
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [targetOffset]);

  // Modify the initial snap effect
  useLayoutEffect(() => {
    if (dimensions.width > 0) {
      // Start at exactly 0 offset
      setTargetOffset(0);
      setCurrentOffset(0);
      setScrollPosition(0);
    }
  }, [dimensions.width]);

  // Update lines when offset changes
  useLayoutEffect(() => {
    const newLines = generateFrets(currentOffset);
    if (JSON.stringify(newLines) !== JSON.stringify(lines)) {
      setLines(newLines);

      // Calculate base fret number
      let firstVisibleFret = Math.floor(scrollPosition);

      // If we're snapping and moving right (current > target), adjust the fret number
      // based on how far through the animation we are
      if (isSnapping && currentOffset > targetOffset && currentOffset > 0.5) {
        firstVisibleFret -= 1;
      }

      const numVisibleFrets = newLines.length;
      
      // Adjust visible frets calculation for fixed mode
      let newVisibleFrets;
      if (!isInfiniteFrets && firstVisibleFret + numVisibleFrets > 24) {
        // If we would exceed fret 24, adjust the starting fret
        const maxStartingFret = 24 - numVisibleFrets + 1;
        firstVisibleFret = Math.max(0, maxStartingFret);
        newVisibleFrets = Array.from(
          { length: numVisibleFrets + (isSnapping ? 1 : 0) }, // Add extra fret during snapping
          (_, i) => firstVisibleFret + i
        ).filter(fret => fret >= 0 && fret <= 24);
      } else {
        newVisibleFrets = Array.from(
          { length: numVisibleFrets + (isSnapping ? 1 : 0) }, // Add extra fret during snapping
          (_, i) => firstVisibleFret + i
        ).filter(fret => fret >= 0 && fret <= 24);
      }

      setVisibleFrets(newVisibleFrets);
    }
  }, [
    currentOffset,
    dimensions.width,
    scrollPosition,
    isSnapping,
    targetOffset,
    currentFretDensity,
    isInfiniteFrets,
    showNodes
  ]);

  // Update the touch event handlers
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      setIsPinching(true);
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      
      setLastPinchDistance(distance);
      
      setTouchpadGestureStart({
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2
      });
    } else if (e.touches.length === 1) {
      setIsDragging(true);
      const touch = e.touches[0];
      lastMouseX.current = isVertical ? touch.clientY : touch.clientX;
    }
  };

  // Add this useEffect to handle touch events with passive: false
  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const touchStartHandler = (e) => {
      if (e.touches.length === 2) {
        e.preventDefault(); // Only prevent default for pinch gestures
      }
      handleTouchStart(e);
    };

    const touchMoveHandler = (e) => {
      if (e.touches.length === 2) {
        e.preventDefault(); // Only prevent default for pinch gestures
      }
      handleTouchMove(e);
    };

    element.addEventListener('touchstart', touchStartHandler, { passive: false });
    element.addEventListener('touchmove', touchMoveHandler, { passive: false });
    element.addEventListener('touchend', handleTouchEnd);
    element.addEventListener('touchcancel', handleTouchCancel);

    return () => {
      element.removeEventListener('touchstart', touchStartHandler);
      element.removeEventListener('touchmove', touchMoveHandler);
      element.removeEventListener('touchend', handleTouchEnd);
      element.removeEventListener('touchcancel', handleTouchCancel);
    };
  }, []);

  // Add touchpad gesture state tracking
  const [touchpadGestureStart, setTouchpadGestureStart] = useState(null);
  const lastTouchRef = useRef(null);

  // Update handleTouchMove for touch events
  const handleTouchMove = (e) => {
    if (!isDragging) return;

    if (isTuningMode && activeTuningString !== null) {
      const touch = e.touches[0];
      
      // Initialize drag start position if not set
      if (!tuningDragStart) {
        setTuningDragStart({
          x: touch.clientX,
          y: touch.clientY,
          initialNote: tuning[isInverted ? activeTuningString : tuning.length - 1 - activeTuningString]
        });
        return;
      }
      
      // Calculate cumulative movement based on orientation with reduced sensitivity
      const dragDistance = (isVertical
        ? tuningDragStart.y - touch.clientY  // Use vertical movement in vertical mode
        : tuningDragStart.x - touch.clientX) * 0.5;  // Use horizontal movement in horizontal mode, reduced by half
      
      // Get the correct tuning index based on inversion
      const tuningIndex = isInverted 
        ? activeTuningString 
        : tuning.length - 1 - activeTuningString;
      
      // Use the initial note as reference for the change
      const newNote = getTunedNote(tuningDragStart.initialNote, dragDistance, useFlats);
      
      // Update tuning if changed
      if (newNote !== tuning[tuningIndex]) {
        const newTuning = [...tuning];
        newTuning[tuningIndex] = newNote;
        onTuningChange?.(newTuning);
      }
      return;
    }

    // Prevent scrolling in tuning mode even if no string is active
    if (isTuningMode) return;

    if (isPinching && e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      
      // Handle pinch zoom distance calculation
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      
      if (lastPinchDistance !== null) {
        const deltaDistance = distance - lastPinchDistance;
        const sensitivity = 0.02;
        
        const logScale = Math.log(currentFretDensity + 1) / Math.log(MAX_DENSITY + 1);
        const scaledSensitivity = sensitivity * (1 + logScale * 2);
        
        // Calculate new density
        const newDensity = currentFretDensity + deltaDistance * scaledSensitivity;
        
        // Only allow density decrease if spacing is valid
        if (newDensity < currentFretDensity) {
          const testLines = generateFrets(currentOffset);
          if (!isValidDensityIncrease(testLines, nodeRadius)) {
            return; // Prevent density decrease
          }
        }

        // Apply clamped density if valid
        const clampedDensity = clamp(newDensity, MIN_DENSITY, MAX_DENSITY);
        setCurrentFretDensity(clampedDensity);
        onFretDensityChange?.(clampedDensity);
      }
      
      setLastPinchDistance(distance);
    } else if (isDragging && e.touches.length === 1) {
      const touch = e.touches[0];
      const currentPosition = isVertical ? touch.clientY : touch.clientX;
      const delta = currentPosition - lastMouseX.current;
      const sensitivity = 0.02;

      let newScrollPosition = scrollPosition - delta * sensitivity;
      
      if (!isInfiniteFrets) {
        const maxScroll = getMaxScroll();
        newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
      }

      setScrollPosition(newScrollPosition);
      setCurrentOffset(newScrollPosition % 1);
      setTargetOffset(newScrollPosition % 1);

      lastMouseX.current = currentPosition;
    }
  };

  const handleTouchEnd = () => {
    if (isPinching) {
      setIsPinching(false);
      setLastPinchDistance(null);
    }
    handleMouseUp();
  };

  const handleTouchCancel = () => {
    if (isPinching) {
      setIsPinching(false);
      setLastPinchDistance(null);
    }
    handleMouseUp();
  };

  // Update the helper function to handle both left and right scales
  const calculateNodeScale = (lines, position) => {
    if (lines.length < 2) return 1;

    if (position === 'nut') {
      const nutBorder = lines[0];
      const firstFret = lines[1];
      const secondFret = lines[2];

      if (!secondFret) return 1;

      const fretDistance = secondFret - firstFret;
      const distanceFromBorder = firstFret - nutBorder;
      
      const normalizedDistance = distanceFromBorder / fretDistance;
      return normalizedDistance >= 0.5 ? 1 : Math.min(1, Math.max(0, normalizedDistance * 2));
    } else if (position === 'right') {
      const bridgeBorder = lines[lines.length - 1];
      const lastFret = lines[lines.length - 2];
      const secondLastFret = lines[lines.length - 3];

      if (!secondLastFret) return 1;

      const fretDistance = lastFret - secondLastFret;
      // Adjust the distance calculation when fret 0 is visible
      const isFret0Visible = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
      const extraOffset = isFret0Visible ? stringSizeBase * 6 : 0; // Account for wider fret 0
      const distanceFromBorder = bridgeBorder - lastFret + stringSizeBase * 4 + extraOffset;
      return Math.min(1, Math.max(0, distanceFromBorder / fretDistance));
    }

    return 1;
  };

  const nutScale = calculateNodeScale(lines, 'nut');
  const rightScale = calculateNodeScale(lines, 'right');

  const prevIntersectionsRef = useRef([]);

  useEffect(() => {
    const newIntersections = [];
    
    stringLinePositions.forEach((y, stringIndex) => {
      const stringRoot = isInverted 
        ? tuning[stringIndex] 
        : tuning[tuning.length - 1 - stringIndex];
      
      lines.forEach((x, fretIndex) => {
        // Skip nodes for fret 0 unless we're showing it in fixed mode
        const isFret0Visible = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
        if (fretIndex === 0 && !isFret0Visible) return;

        // Calculate scale for edge frets
        let scale;
        if (isFret0Visible && fretIndex === 0) {
          scale = 1;
        } else {
          scale = fretIndex === 1
            ? nutScale
            : fretIndex === lines.length - 1
            ? rightScale
            : 1;
        }

        // Calculate base position and width adjustments
        let nodeX = x;
        let fretWidth;
        let positionOffset = 0;

        if (isFret0Visible && fretIndex === 0) {
          // Fret 0 width and position
          fretWidth = fret0Width * scale;
          nodeX = x - fret0Width / 2 - nodeRadius;
        } else {
          // Regular fret width transition for border frets
          const baseWidth = regularFretWidth;
          fretWidth = (fretIndex === 0 || fretIndex === lines.length - 1)
            ? baseWidth * scale
            : baseWidth;
          
          // Adjust position based on width change - reversed directions
          if (fretIndex === 0) {
            positionOffset = -(baseWidth - fretWidth) / 2;
          } else if (fretIndex === lines.length - 1) {
            positionOffset = (baseWidth - fretWidth) / 2;
          }
          
          // Apply position offset and node spacing
          nodeX = x + positionOffset - (nodeRadius + fretWidth / 2) * scale;
        }

        const currentFret = visibleFrets[fretIndex];
        const noteWithOctave = getNoteWithOctave(stringRoot, currentFret, useFlats);
        
        newIntersections.push({
          x: nodeX,
          y,
          radius: nodeRadius * scale,
          opacity: 1,
          stringIndex,
          fretIndex,
          currentFret,
          noteWithOctave
        });
      });
    });

    if (JSON.stringify(newIntersections) !== JSON.stringify(prevIntersectionsRef.current)) {
      setIntersections(newIntersections);
      prevIntersectionsRef.current = newIntersections;
    }
  }, [
    lines,
    stringLinePositions,
    nodeRadius,
    stringSizeBase,
    nutScale,
    rightScale,
    isInfiniteFrets,
    scrollPosition,
    fret0Width,
    regularFretWidth,
    visibleFrets,
    useFlats
  ]);

  // Update the state to track both visibility and animation
  const [nodeVisibility, setNodeVisibility] = useState({
    show: showNodes,
    mounted: showNodes
  });

  // Update the effect to handle both mounting and animation
  useEffect(() => {
    if (showNodes) {
      // Mount all nodes at once
      setNodeVisibility({ show: false, mounted: true });
      
      // Show all nodes together after a brief delay
      const timer = setTimeout(() => {
        setNodeVisibility({ show: true, mounted: true });
      }, 16); // Use a single frame delay
      
      return () => clearTimeout(timer);
    } else {
      // Hide all nodes at once
      setNodeVisibility(prev => ({ ...prev, show: false }));
      
      // Unmount all nodes together after animation
      const timer = setTimeout(() => {
        setNodeVisibility({ show: false, mounted: false });
      }, 200);
      
      return () => clearTimeout(timer);
    }
  }, [showNodes]);

  // Add state for orientation animation
  const [orientationProgress, setOrientationProgress] = useState(isVertical ? 1 : 0);
  
  // Update the orientation animation effect to prevent infinite updates
  useEffect(() => {
    let lastTime = performance.now();
    let animationFrame;
    const targetProgress = isVertical ? 1 : 0;
    const ANIMATION_SPEED = 4;
    const TARGET_FPS = 120;
    const FRAME_TIME = 1000 / TARGET_FPS;
    const COMPLETION_THRESHOLD = 0.001;
    
    const animate = (currentTime) => {
      const deltaTime = currentTime - lastTime;
      
      if (deltaTime >= FRAME_TIME) {
        setOrientationProgress(current => {
          const diff = targetProgress - current;
          if (Math.abs(diff) < COMPLETION_THRESHOLD) {
            return targetProgress;
          }
          
          const step = (ANIMATION_SPEED * deltaTime) / 1000;
          return current + Math.sign(diff) * Math.min(Math.abs(diff), step);
        });
        
        lastTime = currentTime;
      }
      
      animationFrame = requestAnimationFrame(animate);
    };
    
    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [isVertical]); // Only depend on isVertical

  // Move fret recalculation to a separate effect
  useEffect(() => {
    const newLines = generateFrets(currentOffset);
    const firstVisibleFret = Math.floor(scrollPosition);
    const numVisibleFrets = newLines.length;
    
    // Include an extra fret during animation
    const extraFret = 1;
    
    let newVisibleFrets;
    if (!isInfiniteFrets && firstVisibleFret + numVisibleFrets > 24) {
      const maxStartingFret = 24 - numVisibleFrets;
      const startFret = Math.max(0, maxStartingFret);
      newVisibleFrets = Array.from(
        { length: numVisibleFrets + extraFret },
        (_, i) => startFret + i
      ).filter(fret => fret >= 0 && fret <= 24);
    } else {
      newVisibleFrets = Array.from(
        { length: numVisibleFrets + extraFret },
        (_, i) => firstVisibleFret + i
      ).filter(fret => fret >= 0 && fret <= 24);
    }
    
    setVisibleFrets(newVisibleFrets);
  }, [currentOffset, scrollPosition, isInfiniteFrets, orientationProgress]);

  // Add interpolation helper
  const lerp = (start, end, t) => start + (end - start) * t;

  // Modify the SVG render code to interpolate between horizontal and vertical positions
  const isFret0Border = !isInfiniteFrets && Math.floor(scrollPosition) === 0;
  const firstFret = lines[0] || 0;
  const firstFretOffset = isFret0Border ? -(fret0Width / 2) : 0;
  const lastFret = lines[lines.length - 1] || dimensions.width;
  const lastFretOffset = regularFretWidth / 2;  // Add half fret width to extend to right edge

  // Calculate the centering offset once for use across all elements
  const stringSpacing = nodeRadius * 3;
  const totalStringHeight = isVertical
    ? stringSpacing * (Math.max(2, strings) - 1)
    : dimensions.height;
  const verticalCenterOffset = isVertical
    ? (dimensions.height - totalStringHeight) / 2
    : 0;

  // Inside the Diagram component, update the transform in the SVG's <g> element:
  const mainGroupTransform = (() => {
    // Calculate horizontal centering offset for vertical mode
    const horizontalCenter = (dimensions.width - dimensions.height) / 2;
    
    // Interpolate between horizontal and vertical positions
    const xPadding = lerp(
      0, // horizontal mode: no padding
      horizontalCenter, // vertical mode: centered
      orientationProgress
    );
    
    const yPadding = lerp(
      0, // horizontal mode: no padding
      0, // vertical mode: no padding
      orientationProgress
    );

    return `translate(${xPadding}, ${yPadding})`;
  })();

  // Add this effect to notify parent of viewbox dimensions
  useLayoutEffect(() => {
    if (onViewboxChange) {
      onViewboxChange({
        width: dimensions.width,
        height: dimensions.height
      });
    }
  }, [dimensions.width, dimensions.height, onViewboxChange]);

  // Add wheel event handler
  const wheelDebounceRef = useRef(null);

  // Add this helper function near other utility functions
  const isValidDensityIncrease = (lines, nodeRadius) => {
    if (lines.length < 2) return true;
    
    // Get the last two x positions
    const lastX = lines[lines.length - 1];
    const secondLastX = lines[lines.length - 2];
    
    // Calculate the distance between them
    const distance = Math.abs(lastX - secondLastX);
    
    // Compare with node size (diameter) plus fret width
    // Only allow increase if distance is GREATER than node diameter + fret width
    const minimumSpacing = (nodeRadius * 2) + (regularFretWidth * 2);
    return distance >= minimumSpacing;
  };

  // Update handleWheel
  const handleWheel = (e) => {
    if (isTuningMode) {
      e.preventDefault();
      return;
    }

    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY;
      const sensitivity = 0.02;
      
      const logScale = Math.log(currentFretDensity + 1) / Math.log(MAX_DENSITY + 1);
      const scaledSensitivity = sensitivity * (1 + logScale * 2);
      
      // Calculate new density
      const newDensity = currentFretDensity - delta * scaledSensitivity;
      
      // Only allow density decrease if spacing is valid
      if (newDensity < currentFretDensity) {
        // Generate test lines with new density
        const testLines = generateFrets(currentOffset);
        if (!isValidDensityIncrease(testLines, nodeRadius)) {
          return; // Prevent density decrease
        }
      }
      
      // Apply clamped density if valid
      const clampedDensity = clamp(newDensity, MIN_DENSITY, MAX_DENSITY);
      setCurrentFretDensity(clampedDensity);
      onFretDensityChange?.(clampedDensity);
      return;
    }
    
    // Don't prevent default for regular scrolling
    const isTouchpad = Math.abs(e.deltaY) < 120;

    let delta;
    if (isTouchpad) {
      delta = isVertical ? e.deltaY : e.deltaX;
    } else {
      delta = isVertical ? e.deltaY : (e.shiftKey ? e.deltaY : e.deltaX);
    }

    const sensitivity = isTouchpad ? 0.004 : 0.008;
    let newScrollPosition = scrollPosition + delta * sensitivity;
    
    if (!isInfiniteFrets) {
      const maxScroll = getMaxScroll();
      newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
    }

    setScrollPosition(newScrollPosition);
    setCurrentOffset(newScrollPosition % 1);
    setTargetOffset(newScrollPosition % 1);

    if (wheelDebounceRef.current) {
      clearTimeout(wheelDebounceRef.current);
    }

    wheelDebounceRef.current = setTimeout(() => {
      setIsSnapping(true);
      const nearestFret = Math.round(newScrollPosition);
      
      if (!isInfiniteFrets) {
        const limitedFret = clamp(nearestFret, 0, 24);
        setScrollPosition(limitedFret);
      } else {
        setScrollPosition(nearestFret);
      }
      
      setTargetOffset(0);
    }, 100);
  };

  // Add effect to sync with parent's fretDensity prop
  useEffect(() => {
    setCurrentFretDensity(fretDensity);
  }, [fretDensity]);

  // Add this useEffect to handle zoom prevention
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const preventZoom = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    // Only prevent zoom events, let regular touch/wheel events pass through
    container.addEventListener('wheel', preventZoom, { passive: false });

    return () => {
      container.removeEventListener('wheel', preventZoom);
    };
  }, []);

  // Add this effect to recalculate fret numbers immediately when orientation changes
  useEffect(() => {
    const newLines = generateFrets(currentOffset);
    setLines(newLines);

    let firstVisibleFret = Math.floor(scrollPosition);

    if (isSnapping && currentOffset > targetOffset && currentOffset > 0.5) {
      firstVisibleFret -= 1;
    }

    const numVisibleFrets = newLines.length;
    
    let newVisibleFrets;
    if (!isInfiniteFrets && firstVisibleFret + numVisibleFrets > 24) {
      const maxStartingFret = 24 - numVisibleFrets + 1;
      firstVisibleFret = Math.max(0, maxStartingFret);
      newVisibleFrets = Array.from(
        { length: numVisibleFrets + (isSnapping ? 1 : 0) },
        (_, i) => firstVisibleFret + i
      ).filter(fret => fret >= 0 && fret <= 24);
    } else {
      newVisibleFrets = Array.from(
        { length: numVisibleFrets + (isSnapping ? 1 : 0) },
        (_, i) => firstVisibleFret + i
      ).filter(fret => fret >= 0 && fret <= 24);
    }

    setVisibleFrets(newVisibleFrets);
  }, [isVertical, currentOffset, scrollPosition, isInfiniteFrets, isSnapping, targetOffset]);

  // Add an effect to handle initial snap
  useLayoutEffect(() => {
    if (dimensions.width > 0) {
      setIsSnapping(true);
      
      // Ensure we start at exactly 0
      setScrollPosition(0);
      setCurrentOffset(0);
      setTargetOffset(0);

      // Calculate initial visible frets
      const newLines = generateFrets(0);
      const numFrets = calculateVisibleFrets();
      const initialFrets = Array.from(
        { length: numFrets },
        (_, i) => i
      ).filter(fret => fret >= 0 && (!isInfiniteFrets ? fret <= 24 : true));

      setVisibleFrets(initialFrets);
      setLines(newLines);

      // Reset snapping state after animation
      const timer = setTimeout(() => {
        setIsSnapping(false);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [dimensions.width, isInfiniteFrets]);

  // Add this helper function near the top of the file
  const getLuminance = (hexColor) => {
    // Remove the # if present
    const hex = hexColor.replace('#', '');
    
    // Convert hex to RGB
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;
    
    // Calculate relative luminance using sRGB coefficients
    // Using the formula from WCAG 2.0
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    
    // Return true for dark colors (use white text)
    // The threshold 0.5 can be adjusted if needed
    return luminance < 0.5;
  };

  // Add state to track previous scale notes
  const [previousScaleNotes, setPreviousScaleNotes] = useState(new Set());
  const [nodeAnimationStates, setNodeAnimationStates] = useState(new Map());

  // Add this state near the top of the component
  const [activeIntervals, setActiveIntervals] = useState(new Set());

  // Update the scale change effect
  useEffect(() => {
    // Get current scale notes all at once
    const currentScaleNotes = new Set();
    const newAnimationStates = new Map();
    const newActiveIntervals = new Set();  // Track active intervals
    const timestamp = Date.now();
    
    if (selectedKey && selectedScale) {
      intersections.forEach(({ stringIndex, currentFret }) => {
        const rootNote = isInverted 
          ? tuning[stringIndex] 
          : tuning[tuning.length - 1 - stringIndex];
        const noteAtFret = !isInfiniteFrets ? getNoteAtFret(rootNote, currentFret, useFlats) : '';
        
        if (isNoteInScale(noteAtFret, selectedKey, SCALES[selectedScale])) {
          const noteKey = `${stringIndex}-${currentFret}`;
          currentScaleNotes.add(noteKey);
          
          // Track the interval for this note
          if (scaleRoot) {
            const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
            if (intervalIndex !== null) {
              newActiveIntervals.add(intervalIndex);
            }
          }
          
          newAnimationStates.set(noteKey, {
            show: true,
            mounted: true,
            timestamp
          });
        }
      });
    }

    // Handle removed notes
    previousScaleNotes.forEach(noteKey => {
      if (!currentScaleNotes.has(noteKey)) {
        newAnimationStates.set(noteKey, {
          show: false,
          mounted: true,
          timestamp
        });
      }
    });

    // Update all states at once
    setNodeAnimationStates(newAnimationStates);
    setPreviousScaleNotes(currentScaleNotes);
    setActiveIntervals(newActiveIntervals);  // Store active intervals
  }, [selectedKey, selectedScale, intersections, tuning, isInverted, isInfiniteFrets, useFlats, scaleRoot]);

  // Update the node visibility effect
  useEffect(() => {
    if (showNodes) {
      // Mount all nodes at once
      setNodeVisibility({ show: false, mounted: true });
      
      // Show all nodes together after a brief delay
      const timer = setTimeout(() => {
        setNodeVisibility({ show: true, mounted: true });
      }, 16); // Use a single frame delay
      
      return () => clearTimeout(timer);
    } else {
      // Hide all nodes at once
      setNodeVisibility(prev => ({ ...prev, show: false }));
      
      // Unmount all nodes together after animation
      const timer = setTimeout(() => {
        setNodeVisibility({ show: false, mounted: false });
      }, 200);
      
      return () => clearTimeout(timer);
    }
  }, [showNodes]);



  // Add this constant at the top of the file with other constants
  const WHOLETONE_ROOTS = {
    'C': '1',
    'C#': '2'
  };

  // Add this constant alongside WHOLETONE_ROOTS
  const DIMINISHED_ROOTS = {
    'C': 'WH',
    'C#': 'HW'
  };

  // Update the title logic to handle ordering correctly
  const title = isTuningMode ? "Tuning" : (() => {
    if (!selectedScale || selectedScale === 'None') {
      return currentPreset?.instrument || 'Guitar';
    }
    if (selectedScale === 'Chromatic') return 'Chromatic Scale';
    
    // Special handling for Wholetone scale
    if (selectedScale === 'Wholetone') {
      const wholetoneNumber = Object.entries(WHOLETONE_ROOTS)
        .find(([root, _]) => root === selectedKey)?.[1];
      return wholetoneNumber ? `Wholetone Scale ${wholetoneNumber}` : 'Wholetone Scale';
    }
    
    // Special handling for Diminished scale
    if (selectedScale === 'Diminished') {
      const diminishedType = Object.entries(DIMINISHED_ROOTS)
        .find(([root, _]) => root === selectedKey)?.[1];
      return diminishedType ? `${diminishedType} Diminished Scale` : 'Diminished Scale';
    }
    
    // Get the base title with root and scale
    let baseTitle = selectedKey && selectedScale 
      ? `${formatNoteSymbol(selectedKey, accidentalStyle)} ${selectedScale}`
      : selectedScale;

    // If we're in chord mode, prioritize showing chord names over modes
    if ((scaleDisplayMode === SCALE_DISPLAY_MODES.TRIADS || 
         scaleDisplayMode === SCALE_DISPLAY_MODES.TETRACHORDS ||
         scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) && 
        scaleRoot && selectedKey) {
      const intervals = new Set();
      const rootIndex = NOTE_TO_INDEX[selectedKey];
      const scaleRootIndex = NOTE_TO_INDEX[scaleRoot];
      
      if (rootIndex !== undefined && scaleRootIndex !== undefined) {
        const scale = SCALES[selectedScale];
        if (scale) {
          scale.forEach(interval => {
            const noteIndex = (rootIndex + interval) % 12;
            const relativeInterval = (noteIndex - scaleRootIndex + 12) % 12;
            intervals.add(relativeInterval);
          });
          
          const chordQuality = getChordQuality(intervals, scaleDisplayMode);
          if (chordQuality) {
            // For major triads and power chords (fifths), just show the root note
            if (chordQuality === 'M' || scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) {
              return formatNoteSymbol(scaleRoot, accidentalStyle);
            }
            // For all other chords, no space between root and quality
            return `${formatNoteSymbol(scaleRoot, accidentalStyle)}${chordQuality}`;
          }
          // If we're in chord mode but no specific quality, just show the root
          return formatNoteSymbol(scaleRoot, accidentalStyle);
        }
      }
    } 
    
    // If not in chord mode and we have a scale root, show mode names
    else if (scaleRoot && selectedKey) {
      const scaleRootIndex = NOTE_TO_INDEX[scaleRoot];
      const selectedKeyIndex = NOTE_TO_INDEX[selectedKey];
      if (scaleRootIndex !== undefined && selectedKeyIndex !== undefined) {
        const modeIndex = (scaleRootIndex - selectedKeyIndex + 12) % 12;
        
        // Get mode name based on scale type
        let modeName = '';
        if (selectedScale === 'Major' && SCALES.Major.includes(modeIndex)) {
          modeName = MAJOR_MODES[SCALES.Major.indexOf(modeIndex)];
        } else if (selectedScale === 'Melodic Minor' && SCALES['Melodic Minor'].includes(modeIndex)) {
          modeName = MELODIC_MINOR_MODES[SCALES['Melodic Minor'].indexOf(modeIndex)];
        } else if (selectedScale === 'Harmonic Minor' && SCALES['Harmonic Minor'].includes(modeIndex)) {
          modeName = HARMONIC_MINOR_MODES[SCALES['Harmonic Minor'].indexOf(modeIndex)];
        } else if (selectedScale === 'Harmonic Major' && SCALES['Harmonic Major'].includes(modeIndex)) {
          modeName = HARMONIC_MAJOR_MODES[SCALES['Harmonic Major'].indexOf(modeIndex)];
        } else if (selectedScale === 'Pentatonic' && SCALES.Pentatonic.includes(modeIndex)) {
          modeName = PENTATONIC_MODES[SCALES.Pentatonic.indexOf(modeIndex)];
        }
        
        if (modeName) {
          return `${formatNoteSymbol(scaleRoot, accidentalStyle)} ${modeName}`;
        }
      }
    }

    return `${baseTitle} Scale`;
  })();

  // Update keyboard navigation handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Skip if user is typing in an input field or in tuning mode
      if (e.target.tagName === 'INPUT' || 
          e.target.tagName === 'TEXTAREA' || 
          isTuningMode) return;

      const sensitivity = 1.0; // Doubled from 0.5 to 1.0
      let delta = 0;

      switch (e.key) {
        case 'ArrowLeft':
          delta = -sensitivity;
          break;
        case 'ArrowRight':
          delta = sensitivity;
          break;
        default:
          return;
      }

      let newScrollPosition = scrollPosition + delta;
      
      if (!isInfiniteFrets) {
        const maxScroll = getMaxScroll();
        newScrollPosition = clamp(newScrollPosition, 0, maxScroll);
      }

      setScrollPosition(newScrollPosition);
      setCurrentOffset(newScrollPosition % 1);
      setTargetOffset(newScrollPosition % 1);

      // Snap to nearest fret after a delay
      if (wheelDebounceRef.current) {
        clearTimeout(wheelDebounceRef.current);
      }

      wheelDebounceRef.current = setTimeout(() => {
        setIsSnapping(true);
        const nearestFret = Math.round(newScrollPosition);
        
        if (!isInfiniteFrets) {
          const limitedFret = clamp(nearestFret, 0, 24);
          setScrollPosition(limitedFret);
        } else {
          setScrollPosition(nearestFret);
        }
        
        setTargetOffset(0);
      }, 100);
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [scrollPosition, isInfiniteFrets, isTuningMode]); // Add isTuningMode to dependencies

const isFilteredInterval = (intervalIndex, activeIntervals, displayMode) => {
  if (displayMode === SCALE_DISPLAY_MODES.FIFTHS) {
    // For fifths mode, we want to show root, fifth, and either flat 5 or sharp 5 (but not both)
    if (intervalIndex === 6) { // could be sharp 4 or flat 5
      const hasInterval7 = activeIntervals.has(7);
      const hasInterval8 = activeIntervals.has(8);
      const hasInterval9 = activeIntervals.has(9);
      // Show interval 6 only when it's functioning as a flat 5
      // (when we don't have a perfect 5th AND we don't have a sharp 5)
      return !hasInterval7 && !(hasInterval8 && hasInterval9);
    }
    if (intervalIndex === 8) { // could be sharp 5 or b6
      const hasInterval7 = activeIntervals.has(7);
      const hasInterval9 = activeIntervals.has(9);
      // Only show interval 8 when it's functioning as a sharp 5
      // (when we have a major 6th but no perfect 5th)
      return !hasInterval7 && hasInterval9;
    }
    // Always show root and perfect fifth
    return intervalIndex === 0 || intervalIndex === 7;
  }

  // Base triad intervals (removing 4 since it needs special handling)
  const baseTriadIntervals = new Set([0, 3, 7]);
  
  // Add tetrachord intervals
  const tetrachordIntervals = new Set([0, 3, 7, 10, 11]);
  
  // Choose which intervals to check based on display mode
  const intervalsToCheck = displayMode === SCALE_DISPLAY_MODES.TETRACHORDS
    ? tetrachordIntervals
    : baseTriadIntervals;
  
  // Always include these base intervals
  if (intervalsToCheck.has(intervalIndex)) {
    return true;
  }
  
  // Special handling for interval 6 (flat 5)
  if (intervalIndex === 6) {
    const hasInterval7 = activeIntervals.has(7);
    const hasInterval8 = activeIntervals.has(8);
    const hasInterval9 = activeIntervals.has(9);
    // Show interval 6 when it's functioning as a flat 5
    // (when we don't have a perfect 5th AND we don't have a sharp 5)
    return !hasInterval7 && !(hasInterval8 && hasInterval9);
  }
  
  // Special handling for interval 4
  if (intervalIndex === 4) {
    const hasInterval3 = activeIntervals.has(3);
    const hasInterval6 = activeIntervals.has(6);
    
    // Hide interval 4 if we have both intervals 3 and 6
    if (hasInterval3 && hasInterval6) {
      return false;
    }
    return true;
  }
  
  // Special handling for interval 8
  if (intervalIndex === 8) {
    const hasInterval7 = activeIntervals.has(7);
    const hasInterval9 = activeIntervals.has(9);
    
    // Include interval 8 when it's functioning as #5
    if (!hasInterval7 && hasInterval9) {
      return true;
    }
  }
  
  return false;
};

  // Update the getNodeOpacity function to handle all cases
  const getNodeOpacity = (intervalIndex, scaleDisplayMode, activeIntervals, selectedInterval) => {    
    // For FULL mode, always return full opacity
    if (scaleDisplayMode === SCALE_DISPLAY_MODES.FULL) {
      return 1;
    }
    
  // For triads/tetrachords/fifths mode
  if (scaleDisplayMode === SCALE_DISPLAY_MODES.TRIADS || 
      scaleDisplayMode === SCALE_DISPLAY_MODES.TETRACHORDS ||
      scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) {
    // Check if this interval is part of the selected display mode
    const isPartOfChord = isFilteredInterval(intervalIndex, activeIntervals, scaleDisplayMode);
    return isPartOfChord ? 1 : 0.15;
  }
    
    // For interval selection mode
    if (selectedInterval !== null && selectedInterval !== undefined) {
      return intervalIndex === selectedInterval ? 1 : 0.15;
    }
    
    // Default case - full opacity
    return 1;
  };

  // Add these new states near the top with other state declarations
  const [isDraggingStrings, setIsDraggingStrings] = useState(false);
  const [dragStartString, setDragStartString] = useState(null);
  const [dragOperation, setDragOperation] = useState(null); // 'add' or 'remove'

  // Update these handlers to handle touch events properly
  const handleStringMouseDown = (stringNumber, event) => {
    event.stopPropagation();
    event.preventDefault();

    setDragStartString(stringNumber);

    const timer = setTimeout(() => {
      setLongPressActive(true);
      const isCurrentlySelected = selectedStrings.has(stringNumber);
      setDragOperation(isCurrentlySelected ? 'remove' : 'add');
      setSelectedStrings(prev => {
        const newSelection = new Set(prev);
        if (isCurrentlySelected) {
          newSelection.delete(stringNumber);
        } else {
          newSelection.add(stringNumber);
        }
        return newSelection;
      });
    }, LONG_PRESS_DURATION);

    setLongPressTimer(timer);
  };

    // Add these new states near the top with other state declarations
  const [isDraggingNodes, setIsDraggingNodes] = useState(false);
  const [lastPlayedNode, setLastPlayedNode] = useState(null);

  // Update handleNodeClick to handle both click and drag interactions
const handleNodeClick = (note, stringIndex, fretNumber, event) => {
  console.log('Node clicked!');
  console.log('Click mode:', clickMode);
  
  if (!onNodeClick) {
    console.log('onNodeClick is not defined');
    return;
  }
  event?.stopPropagation();

  // Log current state
  console.log('Current nodeDisplayMode:', nodeDisplayMode);
  console.log('Current scaleRoot:', scaleRoot);
  console.log('Clicked note:', note);

  // If in play mode, just pass through the current mode without toggling
  if (clickMode === 'play') {
    console.log('In play mode - keeping current mode');
    onNodeClick(note, stringIndex, fretNumber, nodeDisplayMode);
    return;
  }

  // Handle other click modes
  switch (clickMode) {
    case 'root':
      console.log('In root mode');
      if (nodeDisplayMode === 'notes') {
        // Switching to intervals mode
        setScaleRoot(note);
        onNodeClick(note, stringIndex, fretNumber, 'intervals');
        onNodeDisplayModeChange?.('intervals');
      } else {
        // In intervals mode
        if (note === scaleRoot) {
          // If clicking root, switch back to notes mode
          setScaleRoot(null);
          onNodeClick(note, stringIndex, fretNumber, 'notes');
          onNodeDisplayModeChange?.('notes');
        } else {
          // If clicking different note, set as new root
          setScaleRoot(note);
          onNodeClick(note, stringIndex, fretNumber, 'intervals');
        }
      }
      break;

    case 'edit':
      console.log('In edit mode');
      event.stopPropagation();
      
      // Create a unique key for the node
      const nodeKey = `${stringIndex}-${fretNumber}`;
      
      // Toggle the node's visibility
      setHiddenNodes(prev => {
        const newHiddenNodes = new Set(prev);
        if (newHiddenNodes.has(nodeKey)) {
          newHiddenNodes.delete(nodeKey);
        } else {
          newHiddenNodes.add(nodeKey);
        }
        return newHiddenNodes;
      });
      break;

    default:
      // Same logic for default case
      if (nodeDisplayMode === 'notes') {
        setScaleRoot(note);
        onNodeClick(note, stringIndex, fretNumber, 'intervals');
        onNodeDisplayModeChange?.('intervals');
      } else {
        if (note === scaleRoot) {
          setScaleRoot(null);
          onNodeClick(note, stringIndex, fretNumber, 'notes');
          onNodeDisplayModeChange?.('notes');
        } else {
          setScaleRoot(note);
          onNodeClick(note, stringIndex, fretNumber, 'intervals');
        }
      }
  }
};

  // Update handleNodeMouseDown to include filtering
  const handleNodeMouseDown = (note, stringIndex, fretNumber, event) => {
    // Add this at the start of the function
    if (!isAudioInitialized) {
      onPlayNote?.('');  // Trigger audio initialization with empty note
      return;
    }

    if (clickMode === 'play') {
        event.stopPropagation();
    // Start long press timer
    const timer = setTimeout(() => {
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
      setLongPressActive(true);
      
      // Check if the long-pressed note is the root note
      if (note === scaleRoot) {
        setScaleRoot?.(null);
        onNodeClick?.(null, null, null, 'notes');
        onNodeDisplayModeChange?.('notes');
        event.preventDefault();
        event.stopPropagation();
      } else {
        // Set the root note and switch to intervals mode
        setScaleRoot(note);
        onNodeClick?.(note, stringIndex, fretNumber, 'intervals');
        onNodeDisplayModeChange?.('intervals');
      }
    }, LONG_PRESS_DURATION);

      setLongPressTimer(timer);
      
      setIsDraggingNodes(true);
      
      // Get note info and play
      const stringRoot = isInverted 
        ? tuning[stringIndex] 
        : tuning[tuning.length - 1 - stringIndex];
      const noteWithOctave = getNoteWithOctave(stringRoot, fretNumber, useFlats);
      const nodeKey = `${stringIndex}-${fretNumber}`;

      setClickedNode(nodeKey);
      setNodeTransform({ scale: 1.5 });
      setLastPlayedNode(nodeKey);
      
      if (noteWithOctave) {
        onPlayNote?.(noteWithOctave);
      }
      
      setTimeout(() => {
        setNodeTransform({ scale: 1 });
      }, 150);
    } else if (clickMode === 'edit') {
      event.stopPropagation();
      
      // Start long press timer for edit mode
      const timer = setTimeout(() => {
        if (navigator.vibrate) {
          navigator.vibrate(50);
        }
        setLongPressActive(true);
        console.log("Long pressed in edit mode!"); // Add this line
      }, LONG_PRESS_DURATION);

      setLongPressTimer(timer);
    }
  };

  // Update handleNodeMouseEnter to include filtering
  const handleNodeMouseEnter = (note, stringIndex, fretNumber, event) => {
    if (isDraggingNodes && clickMode === 'play') {
      // Check if this note should be playable
      const stringRoot = isInverted 
        ? tuning[stringIndex] 
        : tuning[tuning.length - 1 - stringIndex];
      const noteAtFret = getNoteAtFret(stringRoot, fretNumber, useFlats);
      
      // Check if note is in scale
      const isInCurrentScale = !selectedKey || !selectedScale || 
        isNoteInScale(noteAtFret, selectedKey, SCALES[selectedScale]);
      
      // Check visibility based on display mode
      let isVisible = true;
      if (scaleDisplayMode !== SCALE_DISPLAY_MODES.FULL) {
        const intervalIndex = scaleRoot ? getIntervalIndex(noteAtFret, scaleRoot) : null;
        if (intervalIndex !== null) {
          const opacity = getNodeOpacity(intervalIndex, scaleDisplayMode, activeIntervals, selectedInterval);
          isVisible = opacity > 0.2;
        }
      }
      
      const nodeKey = `${stringIndex}-${fretNumber}`;
      
      // Only play if note is visible, in scale, and not the last played note
      if (isInCurrentScale && isVisible && nodeKey !== lastPlayedNode) {
        const noteWithOctave = getNoteWithOctave(stringRoot, fretNumber, useFlats);
        
        setClickedNode(nodeKey);
        setNodeTransform({ scale: 1.5 });
        setLastPlayedNode(nodeKey);
        
        if (noteWithOctave) {
          onPlayNote?.(noteWithOctave);
        }
        
        setTimeout(() => {
          setNodeTransform({ scale: 1 });
        }, 150);
      }
    }
  };

  // Update handleNodeMouseLeave to reset lastPlayedNode
  const handleNodeMouseLeave = (note, stringIndex, fretNumber) => {
    setClickedNode(null);
    // Reset lastPlayedNode when the mouse leaves the current node
    const nodeKey = `${stringIndex}-${fretNumber}`;
    if (lastPlayedNode === nodeKey) {
      setLastPlayedNode(null);
    }
    
    // Clear long press timer and state
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
    setLongPressActive(false);
  };

  const handleNodeMouseUp = () => {
    
    if (isDraggingNodes) {
      setIsDraggingNodes(false);
      setClickedNode(null);
    }
    
    // Clear long press timer
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
    setLongPressActive(false);
  };

    // Add these handlers near other node interaction handlers
  const handleNodeTouchStart = (note, stringIndex, fretNumber, event) => {
    // Add this at the start of the function
    if (!isAudioInitialized) {
      onPlayNote?.('');  // Trigger audio initialization with empty note
      return;
    }

    if (clickMode === 'play') {
      event.preventDefault();
      event.stopPropagation();
      
      // Start long press timer
      const timer = setTimeout(() => {
        if (navigator.vibrate) {
          navigator.vibrate(50);
        }
        setLongPressActive(true);
        
        // Check if the long-pressed note is the root note
        if (note === scaleRoot) {
          setScaleRoot?.(null);
          onNodeClick?.(null, null, null, 'notes');
          onNodeDisplayModeChange?.('notes');
          event.preventDefault();
          event.stopPropagation();
        } else {
          // Set the root note and switch to intervals mode
          setScaleRoot(note);
          onNodeClick?.(note, stringIndex, fretNumber, 'intervals');
          onNodeDisplayModeChange?.('intervals');
        }
      }, LONG_PRESS_DURATION);

      setLongPressTimer(timer);
      
      setIsDraggingNodes(true);
      
      // Get note info and play
      const stringRoot = isInverted 
        ? tuning[stringIndex] 
        : tuning[tuning.length - 1 - stringIndex];
      const noteWithOctave = getNoteWithOctave(stringRoot, fretNumber, useFlats);
      const nodeKey = `${stringIndex}-${fretNumber}`;

      setClickedNode(nodeKey);
      setNodeTransform({ scale: 1.5 });
      setLastPlayedNode(nodeKey);
      
      if (noteWithOctave) {
        onPlayNote?.(noteWithOctave);
      }
      
      setTimeout(() => {
        setNodeTransform({ scale: 1 });
      }, 150);
    } else if (clickMode === 'edit') {
      event.preventDefault();
      event.stopPropagation();
      
      // Start long press timer for edit mode
      const timer = setTimeout(() => {
        if (navigator.vibrate) {
          navigator.vibrate(50);
        }
        setLongPressActive(true);
        console.log("Long pressed in edit mode!"); // Add this line
      }, LONG_PRESS_DURATION);

      setLongPressTimer(timer);
    }
  };

const handleNodeTouchMove = (event) => {
  if (!isDraggingNodes || clickMode !== 'play') return;
  
  // Get the current touch position
  const touch = event.touches[0];
  if (!touch) return;
  
  // If we have a drag start position, check if we've moved beyond threshold
  if (dragStartPosition) {
    const deltaX = Math.abs(touch.clientX - dragStartPosition.x);
    const deltaY = Math.abs(touch.clientY - dragStartPosition.y);
    
    // If moved beyond threshold, cancel long press and clear states
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (longPressTimer) {
        clearTimeout(longPressTimer);
        setLongPressTimer(null);
      }
      setLongPressActive(false);
      setDragStartPosition(null);
    }
  }

  event.preventDefault();
  event.stopPropagation();
  
  const svg = containerRef.current?.querySelector('svg');
  if (!svg) return;
  
  const svgRect = svg.getBoundingClientRect();
  const pt = svg.createSVGPoint();
  
  // Calculate touch coordinates based on orientation
  if (isVertical) {
    const horizontalCenter = (svgRect.width - svgRect.height) / 2;
    const additionalOffset = nodeRadius * -2.5; // PADDING.left
    pt.x = touch.clientX - svgRect.left - horizontalCenter + additionalOffset;
    pt.y = touch.clientY - svgRect.top;
  } else {
    pt.x = touch.clientX - svgRect.left;
    pt.y = touch.clientY - svgRect.top;
  }
  
  const svgPoint = pt.matrixTransform(svg.getScreenCTM().inverse());
  
  // Filter valid intersections based on scale and visibility
  const validIntersections = intersections.filter(intersection => {
    const stringRoot = isInverted 
      ? tuning[intersection.stringIndex] 
      : tuning[tuning.length - 1 - intersection.stringIndex];
    const noteAtFret = getNoteAtFret(stringRoot, intersection.currentFret, useFlats);
    
    const isInCurrentScale = !selectedKey || !selectedScale || 
      isNoteInScale(noteAtFret, selectedKey, SCALES[selectedScale]);
    
    let isVisible = true;
    if (scaleDisplayMode !== SCALE_DISPLAY_MODES.FULL) {
      const intervalIndex = scaleRoot ? getIntervalIndex(noteAtFret, scaleRoot) : null;
      if (intervalIndex !== null) {
        const opacity = getNodeOpacity(intervalIndex, scaleDisplayMode, activeIntervals, selectedInterval);
        isVisible = opacity > 0.2;
      }
    }
    
    return isInCurrentScale && isVisible;
  });
  
  // Find closest intersection
  let closestIntersection = null;
  let minDistance = Infinity;
  
  validIntersections.forEach(intersection => {
    let dx, dy;
    if (isVertical) {
      const verticalX = lerp(
        intersection.x,
        dimensions.height - intersection.y - verticalCenterOffset,
        1
      );
      const verticalY = lerp(
        intersection.y,
        intersection.x,
        1
      );
      dx = svgPoint.x - verticalX;
      dy = svgPoint.y - verticalY;
    } else {
      dx = svgPoint.x - intersection.x;
      dy = svgPoint.y - intersection.y;
    }
    
    const distance = Math.sqrt(dx * dx + dy * dy);
    if (distance < intersection.radius * 2 && distance < minDistance) {
      minDistance = distance;
      closestIntersection = intersection;
    }
  });

  // Check if we've moved away from last played node
  if (lastPlayedNode) {
    const [lastStringIndex, lastFretNumber] = lastPlayedNode.split('-').map(Number);
    const lastIntersection = intersections.find(
      i => i.stringIndex === lastStringIndex && i.currentFret === lastFretNumber
    );

    if (lastIntersection) {
      let dx, dy;
      if (isVertical) {
        const verticalX = lerp(
          lastIntersection.x,
          dimensions.height - lastIntersection.y - verticalCenterOffset,
          1
        );
        const verticalY = lerp(
          lastIntersection.y,
          lastIntersection.x,
          1
        );
        dx = svgPoint.x - verticalX;
        dy = svgPoint.y - verticalY;
      } else {
        dx = svgPoint.x - lastIntersection.x;
        dy = svgPoint.y - lastIntersection.y;
      }
      
      const distanceFromLast = Math.sqrt(dx * dx + dy * dy);
      // If moved away from the last node, cancel long press and clear states
      const longPressClearRadius = lastIntersection.radius * 1; // Use radius * 1 for long press
      const noteClearRadius = lastIntersection.radius * 2; // Use radius * 2 for note

      if (distanceFromLast > noteClearRadius) {
        setClickedNode(null);
        setLastPlayedNode(null);
      }

      if (distanceFromLast > longPressClearRadius) {
        if (longPressTimer) {
          clearTimeout(longPressTimer);
          setLongPressTimer(null);
        }
        setLongPressActive(false);
        setDragStartPosition(null);
      }
    }
  }
  
  // Play note if we're on a new intersection
  if (closestIntersection) {
    const nodeKey = `${closestIntersection.stringIndex}-${closestIntersection.currentFret}`;
    if (nodeKey !== lastPlayedNode) {
      const stringRoot = isInverted 
        ? tuning[closestIntersection.stringIndex] 
        : tuning[tuning.length - 1 - closestIntersection.stringIndex];
      const noteWithOctave = getNoteWithOctave(
        stringRoot, 
        closestIntersection.currentFret, 
        useFlats
      );
      
      setClickedNode(nodeKey);
      setNodeTransform({ scale: 1.5 });
      setLastPlayedNode(nodeKey);
      
      if (noteWithOctave) {
        onPlayNote?.(noteWithOctave);
      }
      
      setTimeout(() => {
        setNodeTransform({ scale: 1 });
      }, 150);
    }
  }
};

  const handleNodeTouchEnd = (event) => {
    if (isDraggingNodes) {
      // If we're in play mode, prevent the click event
      if (clickMode === 'play') {
        event?.preventDefault();
        event?.stopPropagation();
      }
      
      setIsDraggingNodes(false);
      setLastPlayedNode(null);
          setClickedNode(null);
    }
    
    // Clear long press timer and state
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
    setLongPressActive(false);
  };

  // Add effect to handle mouse up globally for node dragging
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      handleNodeMouseUp();
    };

    if (isDraggingNodes) {
      window.addEventListener('mouseup', handleGlobalMouseUp);
      window.addEventListener('touchend', handleGlobalMouseUp);
    }

    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
      window.removeEventListener('touchend', handleGlobalMouseUp);
    };
  }, [isDraggingNodes]);

  // Update the string touch handlers
  const handleStringTouchStart = (stringNumber, event) => {
    event.preventDefault();
    event.stopPropagation();

    // Store the initial touch position
    const touch = event.touches[0];
    setDragStartPosition({
      x: touch.clientX,
      y: touch.clientY
    });

    setDragStartString(stringNumber);

    const timer = setTimeout(() => {
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
      setLongPressActive(true);
      
      // Update string selection
      const isCurrentlySelected = selectedStrings.has(stringNumber);
      setDragOperation(isCurrentlySelected ? 'remove' : 'add');
      setSelectedStrings(prev => {
        const newSelection = new Set(prev);
        if (isCurrentlySelected) {
          newSelection.delete(stringNumber);
        } else {
          newSelection.add(stringNumber);
        }
        return newSelection;
      });
    }, LONG_PRESS_DURATION);

    setLongPressTimer(timer);
  };

  const handleStringTouchMove = (event) => {
    if (!dragStartString || !dragStartPosition) return;
    
    event.preventDefault();
    event.stopPropagation();
    
    const touch = event.touches[0];
    const currentPosition = {
      x: touch.clientX,
      y: touch.clientY
    };
    
    const deltaX = Math.abs(currentPosition.x - dragStartPosition.x);
    const deltaY = Math.abs(currentPosition.y - dragStartPosition.y);
    
    // Clear long press timer if movement exceeds threshold
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (longPressTimer) {
        clearTimeout(longPressTimer);
        setLongPressTimer(null);
      }
    }
    
    // Only start dragging if long press is active
    if (longPressActive) {
      setIsDraggingStrings(true);
      setHasGenuinelyDragged(true);
      
      // Use X coordinate for vertical mode, Y for horizontal
      const touchPosition = isVertical ? currentPosition.x : currentPosition.y;
      const closestString = findClosestString(touchPosition);
      
      if (closestString !== null) {
        // Adjust string number calculation based on orientation and inversion
        const stringNumber = isVertical
          ? (isInverted ? closestString + 1 : strings - closestString)
          : (isInverted ? strings - closestString : closestString + 1);
        
        setSelectedStrings(prev => {
          const newSelection = new Set(prev);
          if (dragOperation === 'add') {
            newSelection.add(stringNumber);
          } else {
            newSelection.delete(stringNumber);
          }
          return newSelection;
        });
      }
    }
  };

  // Add new handler for string hover during drag
  const handleStringMouseEnter = (stringNumber) => {
    // Only allow drag selection if long press is active
    if (longPressActive) {
      setSelectedStrings(prev => {
        const newSelection = new Set(prev);
        if (dragOperation === 'add') {
          newSelection.add(stringNumber);
    } else {
          newSelection.delete(stringNumber);
        }
        return newSelection;
      });
    }
  };

  const handleStringDragEnd = (event) => {
    // Clear the long press timeout if it hasn't been triggered yet
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }

    // Only handle selection if we were actually dragging
    if (isDraggingStrings) {
      setIsDraggingStrings(false);
      setDragStartString(null);
      setDragOperation(null);
    } else if (dragStartString !== null && !longPressActive) {
      // This was a quick tap (not a long press)
      const isTouch = event?.type?.startsWith('touch');
      if (isTouch) {
        setSelectedStrings(prev => {
          const newSelection = new Set(prev);
          if (newSelection.has(dragStartString)) {
            newSelection.delete(dragStartString);
    } else {
            newSelection.add(dragStartString);
          }
          onStringSelect?.(Array.from(newSelection));
          return newSelection;
        });
      }
    }

    // Reset all states
    setDragStartString(null);
    setDragStartPosition(null);
    setDragOperation(null);
    setLongPressActive(false);
  };

  // Add effect to handle mouse up globally
  useEffect(() => {
    const handleMouseUp = () => {
      handleStringDragEnd();
    };

    if (isDraggingStrings) {
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDraggingStrings]);

    // Add helper function to find closest string
  const findClosestString = (touchPosition) => {
    const svgElement = containerRef.current?.querySelector('svg');
    if (!svgElement) return null;
    
    const svgRect = svgElement.getBoundingClientRect();
    const point = svgElement.createSVGPoint();
    
    if (isVertical) {
      const horizontalCenter = (dimensions.width - dimensions.height) / 2;
      // For vertical mode, use the Y coordinate for string position
      point.x = touchPosition - svgRect.top; // Use touchPosition directly for Y coordinate
      point.y = svgRect.width / 2 - horizontalCenter;
    } else {
      point.x = svgRect.width / 2;
      point.y = touchPosition - svgRect.top;
    }
    
    const svgPoint = point.matrixTransform(svgElement.getScreenCTM().inverse());
    
    let closestString = null;
    let minDistance = Infinity;
    
    stringLinePositions.forEach((position, index) => {
      // In vertical mode, compare Y coordinates
      const distance = Math.abs(position - (isVertical ? svgPoint.x : svgPoint.y));
      if (distance < minDistance) {
        minDistance = distance;
        closestString = index;
      }
    });
    
    return closestString;
  };

  // Add these new states near the top with other state declarations
  const [isDraggingFrets, setIsDraggingFrets] = useState(false);
  const [dragStartFret, setDragStartFret] = useState(null);
  const [fretDragOperation, setFretDragOperation] = useState(null); // 'add' or 'remove'

  const [longPressTimer, setLongPressTimer] = useState(null);
  const [longPressActive, setLongPressActive] = useState(false)
  const LONG_PRESS_DURATION = 400; //


  const handleFretMouseDown = (fretNumber, event) => {

    setDragStartFret(fretNumber);

    const timer = setTimeout(() => {
      setLongPressActive(true);
      setIsDraggingFrets(true); // Add this line to set dragging state

      const isCurrentlySelected = selectedFrets.has(fretNumber);
      setFretDragOperation(isCurrentlySelected ? 'remove' : 'add');
      setSelectedFrets(prev => {
        const newSelection = new Set(prev);
        if (isCurrentlySelected) {
          newSelection.delete(fretNumber);
        } else {
          newSelection.add(fretNumber);
        }
        return newSelection;
      });
    }, LONG_PRESS_DURATION);

    setLongPressTimer(timer);  // Add this line to store the timer
  };

  const endLongPress = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
    setLongPressActive(false);
  };

  const handleFretMouseEnter = (fretNumber, event) => {
    // Only allow drag selection if long press is active
    if (longPressActive) {

      event?.stopPropagation();
      event?.preventDefault();

      setSelectedFrets(prev => {
        const newSelection = new Set(prev);
        if (fretDragOperation === 'add') {
          newSelection.add(fretNumber);
      } else {
          newSelection.delete(fretNumber);
        }
        return newSelection;
      });
    }
  };

  const handleFretMouseLeave = (fretNumber) => {
    if (longPressActive) {
      return;
    }
    endLongPress();
  };

  const handleStringMouseLeave = (fretNumber) => {
    if (longPressActive) {
      return;
    }
    endLongPress();
  };

  const handleFretDragEnd = (event) => {
    // Clear the long press timeout if it hasn't been triggered yet
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }

    // Only handle selection if we were actually dragging
    if (isDraggingFrets) {
      setIsDraggingFrets(false);
      setDragStartFret(null);
      setFretDragOperation(null);
    } else if (dragStartFret !== null && !longPressActive) {
      // This was a quick tap (not a long press)
      const isTouch = event?.type?.startsWith('touch');
      if (isTouch) {
        setSelectedFrets(prev => {
          const newSelection = new Set(prev);
          if (newSelection.has(dragStartFret)) {
            newSelection.delete(dragStartFret);
          } else {
            newSelection.add(dragStartFret);
          }
          return newSelection;
        });
      }
    }

    // Reset all states
    setDragStartFret(null);
    setDragStartPosition(null);
    setFretDragOperation(null);
    setLongPressActive(false);
  };

  // Update the existing useEffect for drag handling to include fret drag
  useEffect(() => {
    const handleMouseUp = () => {
      handleStringDragEnd();
      handleFretDragEnd();
    };

    if (isDraggingStrings || isDraggingFrets) {
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDraggingStrings, isDraggingFrets]);

  // Add this with the other touch handlers
  const handleFretTouchStart = (fretNumber, event) => {
    // Prevent default to avoid unwanted scrolling/zooming
    event.preventDefault();
    event.stopPropagation();

    // Store initial touch position
    const touch = event.touches[0];
    setDragStartPosition({
      x: touch.clientX,
      y: touch.clientY
    });

    setDragStartFret(fretNumber);

    // Create and store the long press timer
      const timer = setTimeout(() => {
        if (navigator.vibrate) {
          navigator.vibrate(50);
        }
        setLongPressActive(true);
        
      // Update fret selection
      const isCurrentlySelected = selectedFrets.has(fretNumber);
      setFretDragOperation(isCurrentlySelected ? 'remove' : 'add');
      setSelectedFrets(prev => {
        const newSelection = new Set(prev);
        if (isCurrentlySelected) {
          newSelection.delete(fretNumber);
        } else {
          newSelection.add(fretNumber);
        }
        return newSelection;
      });
      }, LONG_PRESS_DURATION);

      setLongPressTimer(timer);
  };

  // Add this cleanup effect
  useEffect(() => {
    return () => {
      // Clean up any existing timer when component unmounts
      if (longPressTimer) {
        clearTimeout(longPressTimer);
      }
    };
  }, [longPressTimer]);

    // Update the fret touch move handler similarly
  const handleFretTouchMove = (event) => {
    if (!dragStartFret || !dragStartPosition) return;
    
    const touch = event.touches[0];
    const currentPosition = {
      x: touch.clientX,
      y: touch.clientY
    };
    
    const deltaX = Math.abs(currentPosition.x - dragStartPosition.x);
    const deltaY = Math.abs(currentPosition.y - dragStartPosition.y);
    
    // Clear long press timer if movement exceeds threshold
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (longPressTimer) {
        clearTimeout(longPressTimer);
        setLongPressTimer(null);
      }
    }
    
    // Only start dragging if long press is active
    if (longPressActive) {
    event.preventDefault();
    event.stopPropagation();

      setIsDraggingFrets(true);
      setHasGenuinelyDragged(true);
      
      const touchX = isVertical ? currentPosition.y : currentPosition.x;
      const closestFret = findClosestFret(touchX);
      
      if (closestFret !== null) {
        setSelectedFrets(prev => {
          const newSelection = new Set(prev);
          if (fretDragOperation === 'add') {
            newSelection.add(closestFret);
      } else {
            newSelection.delete(closestFret);
          }
          return newSelection;
        });
      }
    }
  };

    // Add helper function to find closest fret
  const findClosestFret = (touchPosition) => {
    const svgElement = containerRef.current?.querySelector('svg');
    if (!svgElement) return null;
    
    const svgRect = svgElement.getBoundingClientRect();
    const point = svgElement.createSVGPoint();
    
    // Adjust position based on orientation
    if (isVertical) {
      point.x = svgRect.width / 2;
      point.y = touchPosition;
        } else {
      point.x = touchPosition;
      point.y = svgRect.height / 2;
    }
    
    // Transform point to SVG coordinates
    const svgPoint = point.matrixTransform(svgElement.getScreenCTM().inverse());
    
    // Find closest fret
    let closestFret = null;
    let minDistance = Infinity;
    
    visibleFrets.forEach((fretNumber, index) => {
      const fretPosition = lines[index];
      if (fretPosition === undefined) return;
      
      const distance = Math.abs(fretPosition - (isVertical ? svgPoint.y : svgPoint.x));
      if (distance < minDistance) {
        minDistance = distance;
        closestFret = fretNumber;
      }
    });
    
    return closestFret;
  };

  // Add this new helper function
  const getFretBlockerRect = (x, prevX, firstString, lastString, orientationProgress, dimensions, verticalCenterOffset) => {
    const width = x - prevX;
    const height = lastString - firstString;

    return {
      x: lerp(
        prevX,
        dimensions.height - lastString - verticalCenterOffset,
      orientationProgress
      ),
      y: lerp(
        firstString,
        prevX,
      orientationProgress
      ),
      width: lerp(width, height, orientationProgress),
      height: lerp(height, width, orientationProgress)
    };
  };

  // Modify the fret blocker section to handle touch events
  {/* Fret Deselect Blocker */}
  {selectedFrets.size > 0 && lines.map((x, index) => {
    const fretNumber = visibleFrets[index];
    if (!selectedFrets.has(fretNumber)) return null;

    // Get the previous fret position
    const prevX = lines[index - 1] || (x - regularFretWidth);
    
    // Get string positions for height
    const firstString = stringLinePositions[0];
    const lastString = stringLinePositions[stringLinePositions.length - 1];

    const rect = getFretBlockerRect(
      x,
      prevX,
      firstString,
      lastString,
      orientationProgress,
      dimensions,
      verticalCenterOffset
    );

    return (
      <rect
        key={`fret-highlight-${fretNumber}`}
        {...rect}
        fill="red"
        opacity={0.2}
        onTouchStart={(e) => handleFretTouchStart(fretNumber, e)}
        onTouchMove={(e) => handleFretTouchMove(e)}
        style={{ touchAction: 'none' }}
      />
    );
  })}

  // Add these handlers in src/Diagram.js

  const handleBackgroundMouseMove = (event) => {
    if (isTuningMode || !longPressTimer) return;  // Add isTuningMode check

    // If we don't have a drag start position, create one
    if (!dragStartPosition) {
      setDragStartPosition({
        x: event.clientX,
        y: event.clientY
      });
      return;
    }

    const deltaX = Math.abs(event.clientX - dragStartPosition.x);
    const deltaY = Math.abs(event.clientY - dragStartPosition.y);
    
    // If moved beyond threshold, cancel long press
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (longPressTimer) {
        clearTimeout(longPressTimer);
        setLongPressTimer(null);
      }
      setLongPressActive(false);
      setDragStartPosition(null);
    }
  };

  const handleBackgroundTouchMove = (event) => {
    if (isTuningMode || !longPressTimer) return;  // Add isTuningMode check

    // If we don't have a drag start position, create one
    if (!dragStartPosition) {
      const touch = event.touches[0];
      setDragStartPosition({
        x: touch.clientX,
        y: touch.clientY
      });
      return;
    }

    const touch = event.touches[0];
    const deltaX = Math.abs(touch.clientX - dragStartPosition.x);
    const deltaY = Math.abs(touch.clientY - dragStartPosition.y);
    
    // If moved beyond threshold, cancel long press
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (longPressTimer) {
        clearTimeout(longPressTimer);
        setLongPressTimer(null);
      }
      setLongPressActive(false);
      setDragStartPosition(null);
    }
  };

  // Add new state for background-specific long press
  const [backgroundLongPressTimer, setBackgroundLongPressTimer] = useState(null);
  const [backgroundLongPressActive, setBackgroundLongPressActive] = useState(false);

  // Add separate background long press handlers
  const handleBackgroundStart = (event) => {
    // Prevent handling if we're already dragging nodes or strings
    if (isDraggingNodes || isDraggingStrings) return;

    // Set initial position
    if (event.type === 'touchstart') {
      const touch = event.touches[0];
      setDragStartPosition({
        x: touch.clientX,
        y: touch.clientY
      });
    } else {
      setDragStartPosition({
        x: event.clientX,
        y: event.clientY
      });
    }

    const timer = setTimeout(() => {
      if (navigator.vibrate) {
        navigator.vibrate(50);
      }
      setBackgroundLongPressActive(true);
      if (clickMode === 'play' && nodeDisplayMode === 'intervals') {    
        // Clear scale root and switch display mode to notes
        setScaleRoot?.(null);
        onNodeClick?.(null, null, null, 'notes');
        onNodeDisplayModeChange?.('notes');
      }
      // Reset any other click-related states
      setClickedNode(null);
    }, LONG_PRESS_DURATION);

    setBackgroundLongPressTimer(timer);
  };

  const handleBackgroundMove = (event) => {
    if (!backgroundLongPressTimer) return;

    const currentPosition = event.type === 'touchmove' 
      ? { x: event.touches[0].clientX, y: event.touches[0].clientY }
      : { x: event.clientX, y: event.clientY };

    // If we don't have a drag start position, create one
    if (!dragStartPosition) {
      setDragStartPosition(currentPosition);
      return;
    }

    const deltaX = Math.abs(currentPosition.x - dragStartPosition.x);
    const deltaY = Math.abs(currentPosition.y - dragStartPosition.y);
    
    // If moved beyond threshold, cancel long press
    if (deltaX > DRAG_THRESHOLD || deltaY > DRAG_THRESHOLD) {
      if (backgroundLongPressTimer) {
        clearTimeout(backgroundLongPressTimer);
        setBackgroundLongPressTimer(null);
      }
      setBackgroundLongPressActive(false);
      setDragStartPosition(null);
    }
  };

  const endBackgroundLongPress = () => {
    if (backgroundLongPressTimer) {
      clearTimeout(backgroundLongPressTimer);
      setBackgroundLongPressTimer(null);
    }
    setBackgroundLongPressActive(false);
    setDragStartPosition(null);
  };

  // Add cleanup effect for background timer
  useEffect(() => {
    return () => {
      if (backgroundLongPressTimer) {
        clearTimeout(backgroundLongPressTimer);
      }
    };
  }, [backgroundLongPressTimer]);

  // Add these handler functions
  const handleTuningMouseDown = (stringIndex, e) => {
    if (!isTuningMode) return;
    e.preventDefault();
    setActiveTuningString(stringIndex);
  };

  const handleTuningTouchStart = (stringIndex, e) => {
    if (!isTuningMode) return;
    e.preventDefault();
    setActiveTuningString(stringIndex);
  };

  // Update the DiagramSVG props to include new background handlers
  return (
    <div className="w-full h-full flex">
      <div
        ref={containerRef}
        className="w-full h-full overflow-hidden cursor-grab active:cursor-grabbing flex-1"
        style={{
          backgroundColor: theme.diagramBg,
          userSelect: 'none',
          touchAction: 'pan-x pan-y',
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          display: 'flex',
          flexDirection: 'column',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        onWheel={handleWheel}
      >
<DiagramSVG
          ref={combinedRef}  // Use the combined ref
          svgRef={propsSvgRef || ref}  // Pass either ref to child
          dimensions={dimensions}
          isVertical={isVertical}
          showBounds={showBounds}
          theme={theme}
          title={title}
          titleAreaRef={titleAreaRef}
          unit={unit}
          stringSizeBase={stringSizeBase}
          mainGroupTransform={mainGroupTransform}
          stringLinePositions={stringLinePositions}
          strings={strings}
          isInverted={isInverted}
          selectedStrings={selectedStrings}
          tuning={tuning}
          selectedFrets={selectedFrets}
          intersections={intersections}
          handleStringMouseDown={handleStringMouseDown}
          handleStringMouseEnter={handleStringMouseEnter}
          handleStringMouseLeave={handleStringMouseLeave}
          handleStringTouchStart={handleStringTouchStart}
          handleStringTouchMove={handleStringTouchMove}
          isDraggingStrings={isDraggingStrings}
          nodeRadius={nodeRadius}
          formatNoteSymbol={formatNoteSymbol}
          useFlats={useFlats}
          selectedKey={selectedKey}
          selectedScale={selectedScale}
          SCALES={SCALES}
          isNoteInScale={isNoteInScale}
          nodeVisibility={nodeVisibility}
          lines={lines}
          isFret0Border={isFret0Border}
          fret0Width={fret0Width}
          regularFretWidth={regularFretWidth}
          verticalCenterOffset={verticalCenterOffset}
          getLineWidth={getLineWidth}
          isFretless={isFretless}
          nutScale={nutScale}
          rightScale={rightScale}
          visibleFrets={visibleFrets}
          handleFretMouseDown={handleFretMouseDown}
          handleFretMouseEnter={handleFretMouseEnter}
          handleFretMouseLeave={handleFretMouseLeave}
          handleFretTouchStart={handleFretTouchStart}
          handleFretTouchMove={handleFretTouchMove}
          isDraggingFrets={isDraggingFrets}
          showInlays={showInlays}
          showNodes={showNodes}
          lerp={lerp}
          orientationProgress={orientationProgress}
          firstFret={firstFret}
          firstFretOffset={firstFretOffset}
          lastFret={lastFret}
          lastFretOffset={lastFretOffset}
          isInfiniteFrets={isInfiniteFrets}
          scrollPosition={scrollPosition}
          nodeAnimationStates={nodeAnimationStates}
          getNoteAtFret={getNoteAtFret}
          NOTES={NOTES}
          NOTE_TO_INDEX={NOTE_TO_INDEX}
          accidentalStyle={accidentalStyle}
          colorMode={colorMode}
          nodeDisplayMode={nodeDisplayMode}
          scaleRoot={scaleRoot}
          getIntervalIndex={getIntervalIndex}
          NOTE_COLORS={NOTE_COLORS}
          getNoteColor={getNoteColor}
          noteColors={noteColors}
          rootIndicator={rootIndicator}
          clickedNode={clickedNode}
          nodeTransform={nodeTransform}
          scaleDisplayMode={scaleDisplayMode}
          SCALE_DISPLAY_MODES={SCALE_DISPLAY_MODES}
          activeIntervals={activeIntervals}
          selectedInterval={selectedInterval}
          isDarkMode={isDarkMode}
          getNodeOpacity={getNodeOpacity}
          nodeTextMode={nodeTextMode}
          NODE_TEXT_MODES={NODE_TEXT_MODES}
          getLuminance={getLuminance}
          isFilteredInterval={isFilteredInterval}
          getDisplayIntervalName={getDisplayIntervalName}
          handleNodeMouseDown={handleNodeMouseDown}
          handleNodeMouseEnter={handleNodeMouseEnter}
          handleNodeMouseLeave={handleNodeMouseLeave}
          handleNodeClick={handleNodeClick}
          isDraggingNodes={isDraggingNodes}
          bottomStringThickness={bottomStringThickness}
          handleNodeTouchStart={handleNodeTouchStart}
          handleNodeTouchMove={handleNodeTouchMove}
          handleNodeTouchEnd={handleNodeTouchEnd}
          handleBackgroundStart={handleBackgroundStart}
          handleBackgroundMove={handleBackgroundMove}
          endBackgroundLongPress={endBackgroundLongPress}
          backgroundLongPressActive={backgroundLongPressActive}
          longPressActive={longPressActive}
          endLongPress={endLongPress}
          longPressTimer={longPressTimer}
          handleBackgroundMouseMove={handleBackgroundMouseMove}
          handleBackgroundTouchMove={handleBackgroundTouchMove}
          isTuningMode={isTuningMode}
          handleTuningMouseDown={handleTuningMouseDown}
          handleTuningTouchStart={handleTuningTouchStart}
          activeTuningString={activeTuningString}
          PADDING={PADDING}
          isFullscreen={isFullscreen}
          hiddenNodes={hiddenNodes}
          setHiddenNodes={setHiddenNodes}
        />
      </div>
    </div>
  );
});

export default Diagram;
