import { useEffect, useRef, useCallback } from 'react';

const SoundPlayer = ({ onInit, currentInstrument }) => {
  const audioContext = useRef(null);
  const isInitialized = useRef(false);
  const activeNodes = useRef([]);

  const instruments = {
    instrument: {      
      harmonics: [
        { multiplier: 1, gain: 0.05 },     // fundamental
        { multiplier: 2, gain: 0.25 },    // octave - slightly stronger
        { multiplier: 3, gain: 0.15 },    // perfect fifth - reduced
        { multiplier: 4, gain: 0.12 },    // second octave - reduced
        { multiplier: 5, gain: 0.08 },    // major third - reduced
        { multiplier: 6, gain: 0.06 },    // perfect fifth
        { multiplier: 7, gain: 0.04 },    // minor seventh - reduced
        { multiplier: 8, gain: 0.03 },    // third octave - reduced
        // Removed higher harmonics as they contribute less to guitar tone
      ],
      sustainTime: 0.2,    // Shorter sustain for more pluck-like sound
      releaseTime: 1.2     // Longer release for natural decay
    },
    fretlessInstrument: {
      harmonics: [
        { multiplier: 1, gain: 0.10 },     // fundamental
        { multiplier: 2, gain: 0.10 },    // octave - increased
        { multiplier: 3, gain: 0.20 },    // perfect fifth - increased
        { multiplier: 4, gain: 0.25 },    // second octave - increased
        { multiplier: 5, gain: 0.15 },    // major third - increased
        { multiplier: 6, gain: 0.12 },    // perfect fifth - increased
        { multiplier: 7, gain: 0.08 },    // minor seventh - added
        { multiplier: 8, gain: 0.06 },    // third octave - added
      ],
      attackTime: 0.12,    // Slightly faster attack for better responsiveness
      sustainTime: 0.6,    // Increased sustain
      releaseTime: 2.2,    // Longer release for fuller sound
      filterStart: 2000,    // Starting frequency for filter sweep
      filterEnd: 1000,      // End frequency for filter sweep
      filterQ: 2.5,         // Resonance of the filter
      filterSweepTime: 0.8  // How long the filter sweep takes
    }
  };

  const initAudio = useCallback(async () => {
    if (!isInitialized.current) {
      try {
        audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
        if (audioContext.current.state === 'suspended') {
          await audioContext.current.resume();
        }
        isInitialized.current = true;
        onInit?.(true);
      } catch (error) {
        console.error('Error initializing audio:', error);
        onInit?.(false);
      }
    }
  }, [onInit]);

  useEffect(() => {
    return () => {
      if (audioContext.current && audioContext.current.state !== 'closed') {
        audioContext.current.close();
      }
    };
  }, []);

  const handleVisibilityAndFocus = useCallback(async () => {
    if (audioContext.current && 
        (document.visibilityState === 'visible' || document.hasFocus())) {
      if (audioContext.current.state === 'suspended' || 
          audioContext.current.state === 'interrupted') {
        await audioContext.current.resume();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityAndFocus);
    window.addEventListener('focus', handleVisibilityAndFocus);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityAndFocus);
      window.removeEventListener('focus', handleVisibilityAndFocus);
    };
  }, [handleVisibilityAndFocus]);

  const getInstrumentConfig = () => {
    const instrumentType = ['Violin', 'Viola', 'Cello'].includes(currentInstrument) 
      ? 'fretlessInstrument' 
      : 'instrument';
    
    console.log(`Selected instrument: ${currentInstrument}, Using config: ${instrumentType}`);
    
    return instrumentType;
  };

  const playNote = useCallback(async (noteWithOctave) => {
    if (!isInitialized.current) {
      await initAudio();
    }
    
    if (!audioContext.current || !noteWithOctave) return;

    try {
      // Ensure AudioContext is running
      if (audioContext.current.state === 'suspended') {
        await audioContext.current.resume();
      }

      // Stop all currently playing sounds with a quick fade
      activeNodes.current.forEach(nodes => {
        const quickFadeTime = 0.1; // 15ms fade-out
        nodes.gains.forEach(gain => {
          gain.gain.cancelScheduledValues(audioContext.current.currentTime);
          gain.gain.setValueAtTime(gain.gain.value, audioContext.current.currentTime);
          gain.gain.exponentialRampToValueAtTime(0.001, audioContext.current.currentTime + quickFadeTime);
        });
        // Schedule oscillator stop after the fade
        nodes.oscillators.forEach(osc => {
          osc.stop(audioContext.current.currentTime + quickFadeTime);
        });
      });
      activeNodes.current = [];

      const match = noteWithOctave.match(/([A-G][#b]?)(\d+)/);
      if (!match) return;

      const [, note, octave] = match;
      const noteToFreq = {
        'C': 261.63, 'C#': 277.18, 'Db': 277.18, 'D': 293.66, 'D#': 311.13, 'Eb': 311.13,
        'E': 329.63, 'F': 349.23, 'F#': 369.99, 'Gb': 369.99, 'G': 392.00, 'G#': 415.30,
        'Ab': 415.30, 'A': 440.00, 'A#': 466.16, 'Bb': 466.16, 'B': 493.88
      };
      const baseFreq = noteToFreq[note];
      if (!baseFreq) return;

      const octaveDiff = parseInt(octave) - 4;
      const frequency = baseFreq * Math.pow(2, octaveDiff);

      const instrumentConfig = instruments[getInstrumentConfig()];
      const { sustainTime, releaseTime, harmonics, attackTime = 0 } = instrumentConfig;

      const masterGain = audioContext.current.createGain();
      masterGain.connect(audioContext.current.destination);
      
      // Different gain envelope based on instrument type
      if (getInstrumentConfig() === 'fretlessInstrument') {
        // Create a filter for the string resonance
        const filter = audioContext.current.createBiquadFilter();
        filter.type = 'lowpass';
        filter.Q.value = instrumentConfig.filterQ;
        filter.frequency.setValueAtTime(instrumentConfig.filterStart, audioContext.current.currentTime);
        filter.frequency.exponentialRampToValueAtTime(
          instrumentConfig.filterEnd,
          audioContext.current.currentTime + instrumentConfig.filterSweepTime
        );

        // Insert the filter between masterGain and destination
        masterGain.disconnect();
        masterGain.connect(filter);
        filter.connect(audioContext.current.destination);

        masterGain.gain.setValueAtTime(0, audioContext.current.currentTime);
        masterGain.gain.linearRampToValueAtTime(0.4, audioContext.current.currentTime + attackTime);
        masterGain.gain.setValueAtTime(0.4, audioContext.current.currentTime + attackTime + sustainTime);
        masterGain.gain.exponentialRampToValueAtTime(0.001, audioContext.current.currentTime + attackTime + sustainTime + releaseTime);
      } else {
        masterGain.gain.setValueAtTime(1.0, audioContext.current.currentTime);
        masterGain.gain.linearRampToValueAtTime(0.4, audioContext.current.currentTime + 0.05); // Quick initial decay
        masterGain.gain.exponentialRampToValueAtTime(0.001, audioContext.current.currentTime + sustainTime + releaseTime);
      }

      const currentNoteNodes = {
        oscillators: [],
        gains: [masterGain]
      };

      // Create oscillators for each harmonic
      harmonics.forEach(({ multiplier, gain }) => {
        const osc = audioContext.current.createOscillator();
        const g = audioContext.current.createGain();
        osc.frequency.value = frequency * multiplier;
        g.gain.setValueAtTime(gain, audioContext.current.currentTime);
        osc.connect(g).connect(masterGain);
        osc.start();
        osc.stop(audioContext.current.currentTime + sustainTime + releaseTime);
        
        currentNoteNodes.oscillators.push(osc);
        currentNoteNodes.gains.push(g);
      });

      activeNodes.current.push(currentNoteNodes);
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  }, [initAudio, currentInstrument]);

  return {
    initAudio,
    playNote
  };
};

export default SoundPlayer;
