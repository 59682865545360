// styles.js

export const darkTheme = {
  headerBg: '#333',
  headerColor: '#ccc',
  diagramBg: '#111',
  controlPanelBg: '#333',
  controlPanelGroupBg: '#444',
  textColor: '#ccc',
  buttonBg: '#555',
  buttonColor: '#ccc',
  buttonBgActive: '#777',
  diagramButtonHover: '#333',
  resizeBg: '#555',
  fretColor: '#ccc',
  stringColor: '#ccc',
  labelColor: '#ccc',
  nodeColor: '#ccc',
  diagramButton: "#ccc",
  inlayColor: '#aaa',
  toolbarSelected: '#fff',
  toolbarUnselected: '#aaa',
  rootColor: '#FF0000'
};

export const lightTheme = {
  headerBg: '#333',
  headerColor: '#ccc',
  diagramBg: '#fff',
  controlPanelBg: '#333',
  controlPanelGroupBg: '#444',
  textColor: '#ccc',
  buttonBg: '#555',
  buttonColor: '#ccc',
  buttonBgActive: '#777',
  diagramButtonHover: '#ddd',
  resizeBg: '#aaa',
  fretColor: '#000',
  stringColor: '#000',
  labelColor: '#000',
  nodeColor: '#000',
  diagramButton: "#000",
  inlayColor: '#aaa',
  toolbarSelected: '#fff',
  toolbarUnselected: '#aaa',
  rootColor: '#FF0000'
};