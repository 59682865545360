import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { darkTheme, lightTheme } from './Themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateRight,
  faRotateLeft,
  faFont,
  faSun,
  faMoon,
  faPencil,
  faFileExport,
  faFileCode,
  faLink,
  faArrowsLeftRight,
  faBellSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromSquare } from '@fortawesome/pro-solid-svg-icons';
import FretboardRenderer from './Diagram';
import { exportAsPNG, exportAsSVG } from './Export';
import { INSTRUMENT_PRESETS, NOTES, SCALES, NOTE_TO_INDEX, SCALE_DISPLAY_MODES } from './TheoryControls';
import Theory from './TheoryControls';
import { NODE_TEXT_MODES } from './Diagram';
import HanchnetLogo from './images/HanchnetLogo';
import NotesLogo from './images/NotesLogo';
import SoundPlayer from './components/SoundPlayer';
import { ReactComponent as ZoomIcon } from './images/Zoom.svg';
import { ReactComponent as TapIcon } from './images/Tap.svg';
import { ReactComponent as ScrollIcon } from './images/Scroll-Horizontal.svg';
import { ReactComponent as DragIcon } from './images/Press-and-Hold.svg';
import { copyCurrentUrl } from './components/CopyLink';

// Helper function to convert note to URL format
const noteToUrlFormat = (noteWithOctave) => {
  // Split note and octave (e.g., "E4" -> "E" and "4")
  const match = noteWithOctave.match(/([A-G][#b]?)(\d+)/);
  if (!match) return null;
  const [, note, octave] = match;
  // Replace # with S and b with B for URL safety, but only when it's a flat symbol
  return `${note.replace('#', 'S').replace(/(?<!^)b/, 'B')}${octave}`;
};

// Helper function to convert URL format back to note
const urlFormatToNote = (urlNote) => {
  if (!urlNote) return null;
  // Replace URL safe characters back to musical notation, but only when not the note B
  const note = urlNote.replace('S', '#').replace(/(?<!^)B/, 'b');
  console.log('Converting URL format to note:', urlNote, '→', note);
  return note;
};

// Update the tuning URL handler
const updateTuningInUrl = (tuning, strings, defaultTuning) => {
  console.log('Updating tuning URL:', { tuning, strings, defaultTuning });
  const tuningChanges = [];
  
  if (!tuning || !defaultTuning || !strings) {
    console.warn('Missing required parameters for updateTuningInUrl');
    return;
  }
  
  // Compare with default tuning and only include changes
  for (let i = 0; i < strings; i++) {
    const stringNum = strings - i;
    if (tuning[i] !== defaultTuning[i]) {
      const urlFormat = noteToUrlFormat(tuning[i]);
      if (urlFormat) {
        console.log(`Found tuning change for string ${stringNum}:`, {
          current: tuning[i],
          default: defaultTuning[i],
          urlFormat
        });
        tuningChanges.push(`${stringNum}${urlFormat}`);
      }
    }
  }
  
  console.log('Tuning changes:', tuningChanges);
  
  // Only update URL if there are changes
  if (tuningChanges.length > 0) {
    const newParam = tuningChanges.join('');
    const currentParam = new URLSearchParams(window.location.search).get('t');
    
    // Only update if the parameter would actually change
    if (newParam !== currentParam) {
      console.log('Updating URL parameter from', currentParam, 'to', newParam);
      updateUrlParameter('t', newParam);
    }
  } else {
    // Only remove parameter if it exists
    if (new URLSearchParams(window.location.search).has('t')) {
      updateUrlParameter('t', null);
    }
  }
};

// Update the getInstrumentFromUrl function
const getInstrumentFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const instrumentParam = params.get('i');
  const tuningParam = params.get('t');
  
  // If we have a tuning parameter but no instrument, assume default guitar
  if (!instrumentParam && tuningParam) {
    const defaultInstrument = INSTRUMENT_PRESETS[0];
    let customTuning = [...defaultInstrument.tuning];
    
    try {
      console.log('Parsing tuning parameter without instrument:', tuningParam);
      // Use lookahead to properly split the string
      const tuningMods = tuningParam.match(/\d+[A-G][SB]?\d(?=\d+[A-G]|$)/g);
      console.log('Found tuning modifications:', tuningMods);
      
      if (tuningMods) {
        tuningMods.forEach(mod => {
          // Extract string number and note more carefully
          const stringNum = parseInt(mod[0], 10);
          const noteWithOctave = mod.slice(1);
          const note = urlFormatToNote(noteWithOctave);
          
          console.log(`Processing mod: ${mod}`, {
            stringNum,
            noteWithOctave,
            note,
            fullMod: mod
          });
          
          if (note && stringNum <= defaultInstrument.strings) {
            customTuning[defaultInstrument.strings - stringNum] = note;
          }
        });
        console.log('Final parsed custom tuning:', customTuning);
      }
      
      return {
        instrument: defaultInstrument.instrument,
        strings: defaultInstrument.strings,
        tuning: customTuning
      };
    } catch (error) {
      console.error('Error parsing tuning parameter:', error);
    }
  }
  
  if (!instrumentParam) return null;
  
  // Parse the instrument string (e.g., "Guitar6")
  const match = instrumentParam.match(/([A-Za-z]+)(\d+)/);
  if (!match) return null;
  
  const [, instrument, strings] = match;
  const stringCount = parseInt(strings, 10);
  
  // Check if this instrument/string combination exists in our presets
  const preset = INSTRUMENT_PRESETS.find(
    p => p.instrument === instrument && p.strings === stringCount
  );
  
  // Return null if no matching preset is found
  if (!preset) return null;
  
  // Parse tuning if present
  let customTuning = null;
  if (tuningParam) {
    try {
      console.log('Parsing tuning parameter:', tuningParam);
      const tuningRegex = /(\d+[A-G][SB]?\d+)+/;
      if (tuningRegex.test(tuningParam)) {
        customTuning = [...preset.tuning]; // Start with default tuning
        const tuningMods = tuningParam.match(/\d+[A-G][SB]?\d(?=\d+[A-G]|$)/g);
        console.log('Tuning modifications:', tuningMods);
        
        if (!tuningMods) {
          console.warn('No valid tuning modifications found in:', tuningParam);
          return null;
        }

        tuningMods.forEach(mod => {
          const stringNum = parseInt(mod[0], 10);
          const note = urlFormatToNote(mod.slice(1));
          console.log(`Setting string ${stringNum} to note ${note}`);
          if (note && stringNum <= stringCount) {
            customTuning[stringCount - stringNum] = note;
          }
        });
        console.log('Final custom tuning:', customTuning);
      } else {
        console.warn('Invalid tuning parameter format:', tuningParam);
      }
    } catch (error) {
      console.error('Error parsing tuning parameter:', error);
    }
  }
  
  return {
    instrument,
    strings: stringCount,
    tuning: customTuning
  };
};

// Update the existing updateUrlParameter function
const updateUrlParameter = (param, value) => {
  const url = new URL(window.location);
  if (value) {
    url.searchParams.set(param, value);
  } else {
    url.searchParams.delete(param);
  }
  window.history.pushState({}, '', url);
};

// First, get the instrument and tuning from URL
const initialInstrument = getInstrumentFromUrl();
const defaultPreset = initialInstrument 
  ? INSTRUMENT_PRESETS.find(
      preset => preset.instrument === initialInstrument.instrument && 
                preset.strings === initialInstrument.strings
    )
  : INSTRUMENT_PRESETS[0];

// Make sure we're using the custom tuning if it exists
const initialTuning = initialInstrument?.tuning || defaultPreset.tuning;

// Add console log to verify initial tuning
console.log('Initial tuning setup:', {
  initialInstrument,
  defaultPreset,
  initialTuning,
  urlParams: new URLSearchParams(window.location.search).toString()
});

// Add this after the initialTuning declaration
console.log('App Initialization:', {
  url: window.location.href,
  urlParams: new URLSearchParams(window.location.search).toString(),
  initialInstrument,
  defaultPreset,
  initialTuning,
  tuningParam: new URLSearchParams(window.location.search).get('t')
});

// Update the updateKeyAndScaleInUrl function
const updateKeyAndScaleInUrl = (key, scale) => {
  // Only update URL if values are different from defaults
  if (key && scale && (key !== 'C' || scale !== 'Major')) {
    if (key !== 'C') {
      updateUrlParameter('k', key);
    } else {
      updateUrlParameter('k', null);
    }
    if (scale !== 'Major') {
      updateUrlParameter('s', scale);
    } else {
      updateUrlParameter('s', null);
    }
  } else {
    // Remove parameters if they match defaults
    updateUrlParameter('k', null);
    updateUrlParameter('s', null);
  }
};

// Add function to get key and scale from URL
const getKeyAndScaleFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    key: params.get('k'),
    scale: params.get('s')
  };
};

// Add after the other initial setup code
const urlKeyAndScale = getKeyAndScaleFromUrl();
const initialKey = urlKeyAndScale.key || 'C';
const initialScale = urlKeyAndScale.scale || 'Major';

// Add this with the other URL update functions
const updateScaleRootInUrl = (root) => {
  if (root) {
    updateUrlParameter('r', root);
  } else {
    updateUrlParameter('r', null);
  }
};

// Add this with the other URL getter functions
const getScaleRootFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('r');
};

// Modify the getSelectedFromUrl function to add debugging
const getSelectedFromUrl = (param) => {
  const params = new URLSearchParams(window.location.search);
  const value = params.get(param);
  console.log(`Getting ${param} from URL:`, value);
  
  if (!value) return new Set();
  
  const numbers = value.split(',').map(Number);
  console.log(`Parsed ${param} values:`, numbers);
  
  return new Set(numbers);
};

const urlScaleRoot = getScaleRootFromUrl();
const initialScaleRoot = urlScaleRoot || null;
// Near the top where other initial states are set up
const initialSelectedFrets = getSelectedFromUrl('sf');
const initialSelectedStrings = getSelectedFromUrl('ss');

// Add this console log to debug
console.log('Initial selections from URL:', {
  frets: Array.from(initialSelectedFrets),
  strings: Array.from(initialSelectedStrings)
});

// Add these new helper functions near your other URL-related functions

// Function to get boolean toggles from URL
const getBoolTogglesFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const boolParam = params.get('b') || '';
  
  return {
    isDarkMode: boolParam.includes('d'),
    isVertical: boolParam.includes('v'),
  };
};

// Function to update boolean toggles in URL
const updateBoolTogglesInUrl = (toggles) => {
  let boolString = '';
  if (toggles.isDarkMode) boolString += 'd';
  if (toggles.isVertical) boolString += 'v';
  
  if (boolString) {
    updateUrlParameter('b', boolString);
  } else {
    updateUrlParameter('b', null);
  }
};

const App = () => {
  // Create the ref using useRef
const urlBoolToggles = getBoolTogglesFromUrl();
const [isDarkMode, setIsDarkMode] = useState(urlBoolToggles.isDarkMode);
const [isVertical, setIsVertical] = useState(urlBoolToggles.isVertical);  const [isInverted, setisInverted] = useState(false);
  const [panelSize, setPanelSize] = useState((window.innerHeight - 70) / 2);
  const [strings, setStrings] = useState(defaultPreset.strings);
  const [showNodes, setShowNodes] = useState(true);
  const [stringSizeBase, setStringSizeBase] = useState(1);
  const [contentHeight, setContentHeight] = useState((window.innerHeight - 70) / 2);
  const [isDragging, setIsDragging] = useState(false);
  const [isInfiniteFrets, setIsInfiniteFrets] = useState(false);
  const [showInlays, setShowInlays] = useState(false);
  const [fretDensity, setFretDensity] = useState(3.5);
  const [nodeDisplayMode, setNodeDisplayMode] = useState('notes');
  const [viewBoxHeight, setViewBoxHeight] = useState(0);
  const [currentTuning, setCurrentTuning] = useState(initialTuning);
  const [useFlats, setUseFlats] = useState(false);
  const [accidentalStyle, setAccidentalStyle] = useState('sharp');
  const [showBounds, setShowBounds] = useState(false);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const [selectedKey, setSelectedKey] = useState(initialKey);
  const [selectedScale, setSelectedScale] = useState(initialScale);
  const [noteColors, setNoteColors] = useState({});
  const [scaleRoot, setScaleRoot] = useState(initialScaleRoot);
  const [rootIndicator, setRootIndicator] = useState('circle');
  const [colorMode, setColorMode] = useState(isDarkMode ? 'Rainbow' : 'Default');
  const [currentInstrument, setCurrentInstrument] = useState(defaultPreset.instrument);
  const [currentPreset, setCurrentPreset] = useState(defaultPreset);
  const [showShareModal, setShowShareModal] = useState(false);
  const [activeTuningString, setActiveTuningString] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isWidescreen, setIsWidescreen] = useState(window.innerWidth / window.innerHeight > 16/9);
  const [isFullscreen, setIsFullscreen] = useState(isWidescreen && isTouchDevice);
  const [UNIT, setUnit] = useState(window.innerWidth >= 500 ? 20  : window.innerWidth * 0.04);
const [showFadedNotes, setShowFadedNotes] = useState(true);
const [notificationMessage, setNotificationMessage] = useState('');
const [notificationVisible, setNotificationVisible] = useState(false);
const [hiddenNodes, setHiddenNodes] = useState(new Set());
  const [selectedStrings, setSelectedStrings] = useState(initialSelectedStrings);
  const [selectedFrets, setSelectedFrets] = useState(initialSelectedFrets);


  const soundPlayer = SoundPlayer({ 
    onInit: setIsAudioInitialized,
    currentInstrument: currentInstrument
  });

  const controlPanelRef = useRef(null);
  const controlPanelContentRef = useRef(null);
  const svgRef = useRef(null);

  const theme = isDarkMode ? darkTheme : lightTheme;

  const toggleTheme = () => {
    setIsDarkMode(prev => {
      const newValue = !prev;
      updateBoolTogglesInUrl({
        isDarkMode: newValue,
        isVertical,
      });
      setColorMode(newValue ? 'Rainbow' : 'Default');
      return newValue;
    });
  };

  const toggleInvertStrings = () => {
    setisInverted(!isInverted);
  };

  const toggleFixed = () => {
    setIsInfiniteFrets(!isInfiniteFrets);
  };

  const toggleInlays = () => {
    setShowInlays(!showInlays);
  };

  const toggleOrientation = () => {
    setIsVertical(prev => {
      const newValue = !prev;
      updateBoolTogglesInUrl({
        isDarkMode,
        isVertical: newValue,
      });
      return newValue;
    });
  };

  const handleFretsChange = (e) => {
    setFretDensity(parseFloat(e.target.value));
  };

  const [instrumentChangeFlag, setInstrumentChangeFlag] = useState(0);

  const handleInstrumentChange = (e) => {
    const [instrument, stringCount] = e.target.value.split('|');
    const strings = parseInt(stringCount, 10);
    
    const selectedInstrument = INSTRUMENT_PRESETS.find(
      preset => preset.instrument === instrument && preset.strings === strings
    );

    if (selectedInstrument) {
      setCurrentInstrument(instrument);
      setStrings(selectedInstrument.strings);
      setCurrentTuning(selectedInstrument.tuning);
      setCurrentPreset(selectedInstrument);
      setInstrumentChangeFlag(prev => prev + 1); // Trigger the layout effect
      
      // Update URL with new instrument
      updateUrlParameter('i', `${instrument}${strings}`);
    }
  };

  const handleStringSizeBaseChange = (e) => {
    setStringSizeBase(parseFloat(e.target.value));
  };

  const toggleShowNodes = () => {
    if (selectedScale !== 'None') {
      setShowNodes(!showNodes);
    }
  };

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  useEffect(() => {
    const handleResize = () => {
      const newIsWidescreen = window.innerWidth / window.innerHeight > 7/5;
      setIsWidescreen(newIsWidescreen);
      setIsFullscreen(newIsWidescreen && isTouchDevice);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isTouchDevice]);

  const [dragStartY, setDragStartY] = useState(null);

  useEffect(() => {
  document.body.className = isVertical ? 'vertical' : 'horizontal';
  }, [isVertical]);

  // Add this useEffect in the App component
  useEffect(() => {
    updateKeyAndScaleInUrl(selectedKey, selectedScale);
  }, [selectedKey, selectedScale]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging || dragStartY === null) return;
      
      const totalAvailableHeight = window.innerHeight - 70;
      const minControlPanelHeight = (controlPanelContentRef.current?.scrollHeight || 100) + 40;
      
      // Calculate fixed dimensions
      const UNIT = window.innerWidth >= 500 ? 20 : window.innerWidth * 0.04;
      const nodeRadius = UNIT * 0.75;
      const stringSpacing = nodeRadius * 3;

      if (isVertical) {
        const deltaY = e.clientY - dragStartY;
        const newPanelSize = panelSize - deltaY;
        const availableHeight = totalAvailableHeight - newPanelSize;
        
        setPanelSize(Math.max(minControlPanelHeight, Math.min(newPanelSize, totalAvailableHeight - minControlPanelHeight)));
        setContentHeight(availableHeight);
        setViewBoxHeight(availableHeight);
        setDragStartY(e.clientY);
      } else {
        // Always use at least 2 strings for spacing calculation
        const totalSpacing = stringSpacing * (Math.max(2, strings) - 1);
        const minHeight = totalSpacing + (nodeRadius * 4);
        setPanelSize(Math.max(minControlPanelHeight, totalAvailableHeight - minHeight));
        setContentHeight(minHeight);
      }
    };

    const handleTouchMove = (e) => {
      if (!isDragging || dragStartY === null) return;
      e.preventDefault();
      const touch = e.touches[0];
      
      const totalAvailableHeight = window.innerHeight - 70;
      const minControlPanelHeight = (controlPanelContentRef.current?.scrollHeight || 100) + 40;
      
      // Calculate fixed dimensions
      const UNIT = window.innerWidth >= 500 ? 20 : window.innerWidth * 0.04;
      const nodeRadius = UNIT * 0.75;
      const stringSpacing = nodeRadius * 3;

      if (isVertical) {
        const deltaY = touch.clientY - dragStartY;
        const newPanelSize = panelSize - deltaY;
        const availableHeight = totalAvailableHeight - newPanelSize;
        
        setPanelSize(Math.max(minControlPanelHeight, Math.min(newPanelSize, totalAvailableHeight - minControlPanelHeight)));
        setContentHeight(availableHeight);
        setViewBoxHeight(availableHeight);
        setDragStartY(touch.clientY);
      } else {
        // Always use at least 2 strings for spacing calculation
        const totalSpacing = stringSpacing * (Math.max(2, strings) - 1);
        const minHeight = totalSpacing + (nodeRadius * 4);
        setPanelSize(Math.max(minControlPanelHeight, totalAvailableHeight - minHeight));
        setContentHeight(minHeight);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setDragStartY(null);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging, isVertical, strings, panelSize, dragStartY]);


  // Define PADDING similar to FretboardRenderer.js
 const PADDING = {
    top: isVertical 
      ? UNIT * 0.5  // Reduced padding when inlays are hidden
      : (showInlays ? UNIT * 2.75 : UNIT * 2), // Match bottom padding in horizontal mode
    right: UNIT * 1,
    bottom: isVertical 
      ? UNIT * 2.5
      : (showInlays ? UNIT * 3.75 : UNIT * 4), // Match bottom padding in horizontal mode
    left: UNIT * 2.5,
    title: UNIT * 4
  };

  useEffect(() => {
    const calculateHeights = () => {
      const totalAvailableHeight = window.innerHeight;
      const minControlPanelHeight = (controlPanelContentRef.current?.scrollHeight || 100) + 40;
      
      const nodeRadius = UNIT * 0.75;
      const stringSpacing = nodeRadius * 3;
      
      if (isVertical) {
        const paddingScale = Math.max(1, 1 + (strings * 0.2));
        const TOP_PADDING = nodeRadius * paddingScale;
        const BOTTOM_PADDING = nodeRadius * 2 * paddingScale;
        const fixedGridHeight = stringSpacing * Math.max(2, strings) + TOP_PADDING + BOTTOM_PADDING + 32;
        const newPanelSize = totalAvailableHeight - fixedGridHeight - 80;
        setPanelSize(Math.max(minControlPanelHeight, newPanelSize));
        setContentHeight(fixedGridHeight);
      } else {
        const totalSpacing = stringSpacing * (Math.max(2, strings) - 1);
        const minHeight = totalSpacing + (nodeRadius * 4);
        setPanelSize(Math.max(minControlPanelHeight, totalAvailableHeight - minHeight - 80));
        setContentHeight(minHeight);
      }
    };

    calculateHeights();
  }, [strings, showNodes, isVertical, UNIT]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setDragStartY(e.clientY);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setDragStartY(e.touches[0].clientY);
  };

  const appContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100dvh',
    alignItems: 'center',
    backgroundColor: theme.diagramBg,
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isFullscreen ? 'center' : 'flex-start',
    height: '100dvh',
    width: '100dvw',
    overflow: 'hidden',
  };

  // Add this new style for the diagram section
  const diagramSectionStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: `${UNIT * 16}px`, // Adjust this value as needed
  };

  const resizerStyle = (theme, isDragging) => ({
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    minHeight: `${UNIT * 0.25}px`,
    cursor: 'default',
    color: theme.buttonColor,
    backgroundColor: isDragging ? theme.buttonBg : theme.resizeBg,
    transition: 'background-color 0.2s',
    userSelect: 'none',
    touchAction: 'none',
  });

  const controlPanelContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    height: '100%',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const controlPanelStyle = (theme, panelSize) => ({
    width: '100%',
    flex: 1,
    display: 'flex',
    position: 'relative',
    color: theme.textColor,
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: `${UNIT * 2}px ${UNIT}px ${UNIT * 5}px ${UNIT}px`,
    gap: `${UNIT}px`,
    boxSizing: 'border-box',
    overflow: 'auto'
  });

  // Add callback to handle fret density updates from FretboardRenderer
  const handleFretDensityChange = (newDensity) => {
    setFretDensity(newDensity);
  };

  // Add separate hover states for each button
  const [hoverStates, setHoverStates] = useState({
    infinite: false,
    rotate: false,
    accidental: false,
    nodeText: false,
    theme: false,
    export: false,
    edit: false,
  });

  // Add new ref for title area
  const titleAreaRef = useRef(null);
  const [titlePosition, setTitlePosition] = useState(null);

  // Update effect to use screen coordinates
  useEffect(() => {
    const updateTitlePosition = () => {
      if (titleAreaRef.current) {
        setTitlePosition(titleAreaRef.current);
      }
    };

    const observer = new ResizeObserver(updateTitlePosition);
    
    // Observe the container div instead of the SVG element
    const container = document.querySelector('.w-full.h-full.flex');
    if (container) {
      observer.observe(container);
      updateTitlePosition();
    }

    return () => observer.disconnect();
  }, []);

  // Update the overlayButtonStyle to use screen coordinates
  const overlayButtonStyle = (theme, position = 'right', isHovering) => ({
    backgroundColor: isHovering ? theme.diagramButtonHover : 'transparent',
    color: theme.diagramButton,
    border: 'none',
    cursor: 'pointer',
    width: UNIT * 2,
    height: UNIT * 2,
    borderRadius: UNIT * 0.25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isHovering ? 1 : 1,
    transition: 'all 0.2s'
  });

  const cycleAccidentalStyle = () => {
    setAccidentalStyle(current => {
      switch (current) {
        case 'sharp':
          return 'flat';
        case 'flat':
          return 'sharp';
        default:
          return 'sharp';
      }
    });
  };

  const toggleShowBounds = () => {
    setShowBounds(!showBounds);
  };
  // Add new state for active panel
  const [clickMode, setclickMode] = useState('play');

  // Update the handlePanelChange to only handle visual state
  const handlePanelChange = (newMode) => {
    setclickMode(newMode);
  };

  // Update the renderControlPanelContent to always return Theory component
  const renderControlPanelContent = () => {
    return (
      <Theory
        unit={UNIT}
        selectedKey={selectedKey}
        selectedScale={selectedScale}
        onRootChange={setSelectedKey}
        onScaleChange={setSelectedScale}
        theme={theme}
        onThemeChange={toggleTheme}
        isDarkMode={isDarkMode}
        nodeTextMode={nodeTextMode}
        onNodeTextModeChange={setNodeTextMode}
        onExport={handleExport}
        showBounds={showBounds}
        onShowBoundsChange={toggleShowBounds}
        colorMode={colorMode}
        onColorModeChange={setColorMode}
        noteColors={noteColors}
        onNoteColorChange={(note, color) => {
          setNoteColors(prev => ({
            ...prev,
            [note]: color === 'default' ? null : color
          }));
        }}
        currentPreset={currentPreset}
        onInstrumentChange={handleInstrumentChange}
        onScaleRootChange={setScaleRoot}
        setShowNodes={setShowNodes}
        onInfiniteModeChange={setIsInfiniteFrets}
        onFixedChange={toggleFixed}
        isInfiniteFrets={isInfiniteFrets}
        onAccidentalStyleChange={(style) => setAccidentalStyle(style)}
        scaleDisplayMode={scaleDisplayMode}
        onScaleDisplayModeChange={setScaleDisplayMode}
        selectedInterval={selectedInterval}
        onIntervalChange={handleIntervalChange}
        clickMode={clickMode}
        nodeDisplayMode={nodeDisplayMode}
        onNodeDisplayModeChange={setNodeDisplayMode}
        showGearMenu={showGearMenu}
        onGearClick={toggleGearMenu}
        isInverted={isInverted}
        onInvertChange={toggleInvertStrings}
        isTuningMode={isTuningMode}
        onTuningModeChange={toggleTuningMode}
        currentTuning={currentTuning}
        onResetTuning={handleResetTuning}
        onTuningChange={handleTuningChange}
        isFullscreen={isFullscreen}
        showFadedNotes={showFadedNotes}
        setShowFadedNotes={setShowFadedNotes}
      />
    );
  };

  // Update the helper function to check for scales that don't need accidentals
  const currentScaleHasAccidentals = () => {
    if (!selectedScale) return false;
    
    // Show for chromatic scale - move this check to the top
    if (selectedScale === 'Chromatic') return true;
    
    if (!selectedKey) return false;
    
    // Hide for major scale and its modes
    if (selectedScale === 'Major' || 
        selectedScale === 'Melodic Minor' || 
        selectedScale === 'Harmonic Minor' || 
        selectedScale === 'Harmonic Major') {
      return false;
    }
    
    // For other scales, check if they contain any accidentals
    if (!SCALES[selectedScale]) return false;
    
    const rootIndex = NOTE_TO_INDEX[selectedKey];
    const scaleNotes = SCALES[selectedScale].map(interval => {
      const noteIndex = (rootIndex + interval) % 12;
      return NOTES[noteIndex];
    });
    
    return scaleNotes.some(note => Array.isArray(note));
  };

  const [showNodeText, setShowNodeText] = useState(true);

  const [nodeTextMode, setNodeTextMode] = useState(NODE_TEXT_MODES.SHOW_ALL);

  // Add this to your state declarations
  const [scaleDisplayMode, setScaleDisplayMode] = useState(SCALE_DISPLAY_MODES.TRIADS);

  // Add new state for selected interval
  const [selectedInterval, setSelectedInterval] = useState(0);

  // Add handler for interval changes
  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  // Update the logo container style
  const logoContainerStyle = {
    position: 'absolute',
    bottom: `${UNIT * 0.25}px`,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 10,
    opacity: 1,
    transition: 'opacity 0.2s',
    overflow: 'visible',
  };

  const hanchnetLogoStyle = {
    width: `${UNIT * 7}px`,
    height: 'auto',
    color: '#777',
    cursor: 'pointer',
  };

  const notesLogoStyle = {
    width: '70%',
    height: 'auto',
    marginTop: `-${UNIT * 0.5}px`,
    overflow: 'visible',
    color: '#faa41a',
    display: 'block'
  };

  const handleLogoClick = () => {
    window.open('https://hanch.net', '_blank');
  };

  // Add this base style for the top corner buttons container
  const cornerButtonsTopStyle = (theme) => ({
    position: isFullscreen ? 'fixed' : 'absolute',
    top: isFullscreen ? UNIT * 1.75 : titlePosition?.center || PADDING.title / 2,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${UNIT * 0.5}px`,
    zIndex: 2,
    transform: 'translateY(-50%)'
  });

  // Simplify the left and right styles to only include unique properties
  const cornerButtonsTopLeftStyle = {
    display: 'flex'
  };

  const cornerButtonsTopRightStyle = {
    display: 'flex'
  };

  // Add this style definition near your other styles
  const cornerButtonsBottomRightStyle = (theme) => ({
    position: 'absolute',
    right: UNIT * 0.5,
    bottom: UNIT * 0.5,
    display: 'flex',
    zIndex: 2,
  });

    // Add this style definition near your other styles
  const cornerButtonsBottomLeftStyle = (theme) => ({
    position: 'absolute',
    left: UNIT * 0.5,
    bottom: UNIT * 0.5,
    display: 'flex',
    zIndex: 2,
  });

  // Add this style specifically for the export button
  const shareButtonStyle = (theme, isHovering) => ({
    ...overlayButtonStyle(theme, 'right', isHovering),
    backgroundColor: isHovering ? theme.buttonBg : 'transparent',
    color: theme.buttonColor,
  });

  // Add new style for the container
  const panelSectionStyle = (theme) => ({
    position: isFullscreen ? 'absolute' : 'relative',
    display: isFullscreen ? 'none' : 'flex',
    backgroundColor: theme.controlPanelBg,
    backgroundImage: `url(images/tile-diagonal.png)`,
    backgroundSize: `${UNIT * 1.5}px`,
    backgroundRepeat: 'repeat',
    flexDirection: 'column',
    flex: 1,
  });

  // Add new state for overlay opacity
  const [overlayOpacity, setOverlayOpacity] = useState(1);

  // Update the handleFirstInteraction function
  const handleFirstInteraction = async () => {
    if (!isAudioInitialized) {
      // Start fade out
      setOverlayOpacity(0);
      // Wait for fade animation to complete before initializing audio
      setTimeout(async () => {
        await soundPlayer.initAudio();
      },300); // Match this with CSS transition duration
    }
  };

  const handleBackgroundClick = (e) => {
    setScaleRoot?.(null);
    setNodeDisplayMode('notes');
    e.preventDefault();
    e.stopPropagation();
  };

  // Add this with other state declarations at the top
  const [showGearMenu, setShowGearMenu] = useState(false);

  // Add this with other function declarations
  const toggleGearMenu = () => {
    setShowGearMenu(prev => !prev);
  };

  // Add new state near other state declarations
  const [isTuningMode, setIsTuningMode] = useState(false);

  // Add handler near other handlers
  const toggleTuningMode = () => {
    setIsTuningMode(prev => !prev);
    // When enabling tuning mode, ensure we're in 'notes' display mode
    if (!isTuningMode) {
      setNodeDisplayMode('notes');
      setShowNodes(true);
    }
  };

  // Add handler near other state handlers
  const handleTuningChange = (newTuning) => {
    setCurrentTuning(newTuning);
    
    // Get default tuning for current instrument
    const defaultTuning = INSTRUMENT_PRESETS.find(
      preset => preset.instrument === currentPreset.instrument && 
                preset.strings === currentPreset.strings
    )?.tuning;
    
    if (defaultTuning) {
      // Log for debugging
      console.log('Updating tuning URL:', {
        newTuning,
        defaultTuning,
        strings
      });
      updateTuningInUrl(newTuning, strings, defaultTuning);
    }
  };

  // Add this handler for resetting tuning
  const handleResetTuning = () => {
    const defaultPreset = INSTRUMENT_PRESETS.find(
      preset => preset.instrument === currentPreset.instrument && 
                preset.strings === currentPreset.strings
    );
    
    if (defaultPreset) {
      setCurrentTuning(defaultPreset.tuning);
      // Clear the tuning parameter from URL when resetting
      updateUrlParameter('t', null);
    }
  };

const handleExport = async () => {
  try {
    if (!svgRef.current) {
      console.error('SVG ref is null:', svgRef);
      throw new Error('SVG element not found');
    }

    console.log('Exporting SVG:', svgRef.current);
    
    // Add a small delay to ensure styles are applied
    await new Promise(resolve => setTimeout(resolve, 100));
    
    await exportAsPNG(svgRef.current, 'fretboard.png');
    setNotificationMessage('Export successful');
  } catch (error) {
    console.error('Export failed:', error);
    setNotificationMessage(`Export failed: ${error.message}`);
  }
};

  const exportAsSVG = (svgElement) => {
    if (!svgElement) {
      console.error('No SVG element found');
      return;
    }

    // Clone the SVG to avoid modifying the original
    const clonedSvg = svgElement.cloneNode(true);
    
    // Convert SVG to string
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(clonedSvg);
    
    // Create blob and download
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'fretboard.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

useEffect(() => {
  const handlePopState = () => {
    const urlInstrument = getInstrumentFromUrl();
    const urlKeyAndScale = getKeyAndScaleFromUrl();
    const urlScaleRoot = getScaleRootFromUrl();

    if (urlInstrument) {
      const selectedInstrument = INSTRUMENT_PRESETS.find(
        preset => preset.instrument === urlInstrument.instrument && 
                  preset.strings === urlInstrument.strings
      );

      if (selectedInstrument) {
        setCurrentInstrument(urlInstrument.instrument);
        setStrings(urlInstrument.strings);
        setCurrentTuning(urlInstrument.tuning || selectedInstrument.tuning);
        setCurrentPreset(selectedInstrument);
      }
    }

    // Update key and scale from URL
    if (urlKeyAndScale.key) {
      setSelectedKey(urlKeyAndScale.key);
    }
    if (urlKeyAndScale.scale) {
      setSelectedScale(urlKeyAndScale.scale);
    }

    // Add scale root handling
    if (urlScaleRoot) {
      setScaleRoot(urlScaleRoot);
    } else {
      setScaleRoot(null);
    }
  };

  window.addEventListener('popstate', handlePopState);
  return () => window.removeEventListener('popstate', handlePopState);
}, []);

useEffect(() => {
  // Update URL when tuning changes
  if (currentPreset && currentTuning) {
    const defaultTuning = INSTRUMENT_PRESETS.find(
      preset => preset.instrument === currentPreset.instrument && 
                preset.strings === currentPreset.strings
    )?.tuning;

    if (defaultTuning) {
      console.log('Tuning changed, updating URL:', {
        currentTuning,
        defaultTuning,
        strings: currentPreset.strings
      });
      updateTuningInUrl(currentTuning, strings, defaultTuning);
    }
  }
}, [currentTuning, currentPreset]);

// In the App component, add a useEffect to handle URL changes
useEffect(() => {
  const params = new URLSearchParams(window.location.search);
  const tuningParam = params.get('t');
  
  if (tuningParam) {
    const urlInstrument = getInstrumentFromUrl();
    if (urlInstrument?.tuning) {
      console.log('Setting tuning from URL:', urlInstrument.tuning);
      // Compare with current tuning before updating
      if (JSON.stringify(urlInstrument.tuning) !== JSON.stringify(currentTuning)) {
        setCurrentTuning(urlInstrument.tuning);
      }
    }
  }
}, []); // Empty dependency array to only run once on mount

useEffect(() => {
  updateScaleRootInUrl(scaleRoot);
}, [scaleRoot]);

useEffect(() => {
  const urlScaleRoot = getScaleRootFromUrl();
  if (urlScaleRoot) {
    setScaleRoot(urlScaleRoot);
    setNodeDisplayMode('intervals');
  }
}, []); // Empty dependency array to only run once on mount

// Add these style definitions with your other styles
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(8px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1500,
};

const modalContentStyle = (theme) => ({
  backgroundColor: theme.controlPanelBg,
  padding: UNIT,
  borderRadius: UNIT,
  minWidth: UNIT * 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: UNIT * 0.5,
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',
});

const modalButtonStyle = (theme) => ({
  backgroundColor: theme.buttonBg,
  color: theme.buttonColor,
  border: 'none',
  fontSize: `${UNIT * 1}px`,
  fontWeight: 'bold',
  borderRadius: `${UNIT * 0.5}px`,
  cursor: 'pointer',
  height: `${UNIT * 2}px`,
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: `${UNIT * 0.5}px`,
  justifyContent: 'center',
  width: '100%',
});

const tuningOverlayKeyframes = `
  @keyframes slideLeftRight {
    0% { transform: translateX(-${UNIT}px); }
    25% { transform: translateX(${UNIT}px); }
    50% { transform: translateX(-${UNIT}px); }
    75% { transform: translateX(${UNIT}px); }
    100% { transform: translateX(-${UNIT}px); }
  }
`;

useEffect(() => {
  setIsTouchDevice('ontouchstart' in window || 
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0);
}, []);

const [showTuningOverlay, setShowTuningOverlay] = useState(false);

// Add this with your other state declarations
const [hasShownTuningOverlay, setHasShownTuningOverlay] = useState(false);

// Then update the useEffect
useEffect(() => {
  let timeoutId;
  if (isTuningMode && !hasShownTuningOverlay) {
    // Show overlay when tuning mode is first enabled
    setShowTuningOverlay(true);
    setHasShownTuningOverlay(true);
    
    // Hide after animation
    timeoutId = setTimeout(() => {
      setShowTuningOverlay(false);
    }, 1000);
  } else if (!isTuningMode) {
    // Reset states when tuning mode is disabled
    setShowTuningOverlay(false);
    setHasShownTuningOverlay(false);
  }
  
  return () => {
    if (timeoutId) clearTimeout(timeoutId);
  };
}, [isTuningMode]); // Only depend on isTuningMode, not activeTuningString

// Add useLayoutEffect to handle instrument changes
useLayoutEffect(() => {
  if (instrumentChangeFlag > 0) {
    // Force a re-render of the nodes
    setShowNodes(false);
    requestAnimationFrame(() => {
      setShowNodes(true);
    });
  }
}, [instrumentChangeFlag]);

// Add with other state declarations
const [showNotification, setShowNotification] = useState(false);

// Add with other style definitions
const notificationStyle = (theme) => ({
  position: 'fixed',
  bottom: UNIT,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'black',
  color: theme.buttonColor,
  padding: `${UNIT * 0.5}px ${UNIT}px`,
  borderRadius: UNIT * 0.5,
  fontSize: `${UNIT * 0.8}px`,
  zIndex: 2000,
  opacity: notificationVisible ? 1 : 0,
  transition: 'opacity 0.3s ease-in-out',
  pointerEvents: 'none',
  visibility: notificationMessage ? 'visible' : 'hidden',
});

useEffect(() => {
  let visibilityTimeout;
  let messageTimeout;

  if (notificationMessage) {
    // Show notification immediately
    setNotificationVisible(true);
    
    // Clear any existing timeouts
    if (visibilityTimeout) clearTimeout(visibilityTimeout);
    if (messageTimeout) clearTimeout(messageTimeout);
    
    // Start fade out after 2 seconds
    visibilityTimeout = setTimeout(() => {
      setNotificationVisible(false);
      
      // Remove message after fade out animation completes
      messageTimeout = setTimeout(() => {
        setNotificationMessage('');
      }, 300); // Match this with CSS transition duration
    }, 2000);
  }
  
  // Cleanup function
  return () => {
    if (visibilityTimeout) clearTimeout(visibilityTimeout);
    if (messageTimeout) clearTimeout(messageTimeout);
  };
}, [notificationMessage]);

// Add these functions where you have other URL-related functions
const getSelectedFromUrl = (param) => {
  const params = new URLSearchParams(window.location.search);
  const value = params.get(param);
  if (!value) return new Set();
  return new Set(value.split(',').map(Number));
};

const updateSelectedInUrl = (param, selected) => {
  const params = new URLSearchParams(window.location.search);
  if (selected.size > 0) {
    const sortedValues = Array.from(selected).sort((a, b) => a - b);
    params.set(param, sortedValues.join(','));
  } else {
    params.delete(param);
  }
  
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, '', newUrl);
};

// Add these useEffect hooks in App.js
useEffect(() => {
  // Load initial selected strings and frets from URL
  const urlStrings = getSelectedFromUrl('ss');
  const urlFrets = getSelectedFromUrl('sf');
  
  setSelectedStrings(urlStrings);
  setSelectedFrets(urlFrets);
}, []); // Empty dependency array for initial load only

// Update URL when selections change
useEffect(() => {
  updateSelectedInUrl('ss', selectedStrings);
}, [selectedStrings]);

useEffect(() => {
  updateSelectedInUrl('sf', selectedFrets);
}, [selectedFrets]);

// Update your existing copyCurrentUrl function
const copyCurrentUrl = async (setNotificationMessage) => {
  try {
    const params = new URLSearchParams(window.location.search);
    
    // Add selected strings and frets to URL if they exist
    if (selectedStrings.size > 0) {
      const sortedStrings = Array.from(selectedStrings).sort((a, b) => a - b);
      params.set('ss', sortedStrings.join(','));
    }
    
    if (selectedFrets.size > 0) {
      const sortedFrets = Array.from(selectedFrets).sort((a, b) => a - b);
      params.set('sf', sortedFrets.join(','));
    }

    const url = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    await navigator.clipboard.writeText(url);
    setNotificationMessage('Custom URL copied to clipboard');
  } catch (error) {
    console.error('Failed to copy URL:', error);
    setNotificationMessage('Failed to copy URL');
  }
};

// Add these useEffect hooks in the App component
useEffect(() => {
  updateBoolTogglesInUrl({
    isDarkMode,
    isVertical,
  });
}, [isDarkMode, isVertical]);

// Update the existing popstate handler to include bool toggles
useEffect(() => {
  const handlePopState = () => {
    const urlBoolToggles = getBoolTogglesFromUrl();
    setIsDarkMode(urlBoolToggles.isDarkMode);
    setIsVertical(urlBoolToggles.isVertical);
    
    // ... existing popstate handling code ...
  };

  window.addEventListener('popstate', handlePopState);
  return () => window.removeEventListener('popstate', handlePopState);
}, []);

  return (
    <div 
      onMouseDown={handleFirstInteraction}
      onTouchStart={handleFirstInteraction}
      style={appContainerStyle}
    >
      <div style={contentStyle}>
        <div className="diagramSection" style={diagramSectionStyle}>
          {/* Launch screen - Only show overlay when audio is not initialized */}
          {!isAudioInitialized && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: `rgba(127, 127, 127, ${overlayOpacity * 0.95})`,
              backdropFilter: `blur(${overlayOpacity * 3}px)`,
              WebkitBackdropFilter: `blur(${overlayOpacity * 3}px)`,
              zIndex: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              fontSize: UNIT * 2,
              color: theme.diagramBg,
              fontWeight: 'bold',
              textAlign: 'center',
              opacity: overlayOpacity,
              width: '100%',
              padding: `${UNIT * 2}px 0`,
              transition: 'all 300ms ease-out',
              gap: UNIT,
            }}>
              <div style={{
                position: 'absolute',
                color: theme.diagramBg,
                fontWeight: 'bold',
                opacity: 1,
              }}>
              </div>
              <div style={{ 
                position: 'absolute',
                color: 'pink',
                fontSize: `${UNIT * 0.8}px`,
                textAlign: 'center',
                fontStyle: 'italic',
                width: 'auto',
                bottom: UNIT,
                left: 0,
                right: 0,
              }}>
                * Audio may not play if device is in <FontAwesomeIcon icon={faBellSlash} /> "Silent Mode"
              </div>
              {/* Top row of icons */}
              <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                width: '100%',
                top: UNIT * 3,
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: UNIT,
                }}>
                  <div style={{ position: 'relative' }}>
                    <div style={{
                      position: 'absolute',
                      left: '50%',
                      top: -UNIT * 1.2,
                      transform: 'translateX(-50%)',
                      fontSize: UNIT * 0.8,
                      color: theme.diagramBg,
                      whiteSpace: 'nowrap',
                      textDecoration: 'underline',
                    }}>
                      Tap
                    </div>
                    <TapIcon style={{ 
                      width: UNIT * 4,
                      height: UNIT * 4,
                      color: '#ccc'                      
                    }} />
                  </div>
                  <div style={{
                    fontSize: UNIT,
                    color: theme.diagramBg,
                  }}>
                    Play note<span style={{ color: 'pink' }}>*</span>
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: UNIT,
                }}>
                  <div style={{ position: 'relative' }}>
                    <div style={{
                      position: 'absolute',
                      left: '50%',
                      top: -UNIT * 1.2,
                      transform: 'translateX(-50%)',
                      fontSize: UNIT * 0.8,
                      color: theme.diagramBg,
                      whiteSpace: 'nowrap',
                      textDecoration: 'underline',

                    }}>
                      Swipe
                    </div>
                    <ScrollIcon style={{ 
                      width: UNIT * 4,
                      height: UNIT * 4,
                      color: '#ccc'
                    }} />
                  </div>
                  <div style={{
                    fontSize: UNIT,
                    textAlign: 'left',
                    color: theme.diagramBg,
                  }}>
                    Scroll
                  </div>
                </div>
              </div>
              {/* Bottom row of icons */}
              <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                width: '100%',
                bottom: UNIT * 3,
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: UNIT,
                }}>
                  <div style={{ position: 'relative' }}>
                    <div style={{
                      position: 'absolute',
                      left: '50%',
                      top: -UNIT * 1.2,
                      transform: 'translateX(-50%)',
                      fontSize: UNIT * 0.8,
                      color: theme.diagramBg,
                      whiteSpace: 'nowrap',
                      textDecoration: 'underline',
                    }}>
                      Hold
                    </div>
                    <DragIcon style={{ 
                      width: UNIT * 4,
                      height: UNIT * 4,
                      color: '#ccc'
                    }} />
                  </div>
                  <div style={{
                    fontSize: UNIT * 0.8,
                    textAlign: 'left',
                    color: theme.diagramBg,
                  }}>
                    <div>Select note /</div>
                    <div>Toggle labels</div>
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: UNIT,
                }}>
                  <div style={{ position: 'relative' }}>
                    <div style={{
                      position: 'absolute',
                      left: '50%',
                      top: -UNIT * 1.2,
                      transform: 'translateX(-50%)',
                      fontSize: UNIT * 0.8,
                      color: theme.diagramBg,
                      whiteSpace: 'nowrap',
                      textDecoration: 'underline',

                    }}>
                      Pinch
                    </div>
                    <ZoomIcon style={{ 
                      width: UNIT * 4,
                      height: UNIT * 4,
                      color: '#ccc'
                    }} />
                  </div>
                  <div style={{
                    fontSize: UNIT,
                    textAlign: 'left',
                    color: theme.diagramBg,
                  }}>
                    Zoom
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Fullscreen padding top */}
          <div style={{
            backgroundColor: theme.diagramBg,
          }} />

          {/* Tuning mode overlay */}
          {isTuningMode && !activeTuningString && (
            <>
              <style>{tuningOverlayKeyframes}</style>
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: `rgba(0, 0, 0, ${isDarkMode ? 0.3 : 0.1})`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 4,
                pointerEvents: 'none',
                opacity: showTuningOverlay ? 1 : 0,
                transition: 'opacity 0.5s ease-out',
              }}>
                <FontAwesomeIcon 
                  icon={faArrowsLeftRight} 
                  style={{
                    top:`${PADDING.title / 2}px)`,
                    fontSize: UNIT * 8,
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.75)' : 'rgba(255, 255, 255, 1)',
                    animation: 'slideLeftRight 1s ease-in-out infinite',
                  }}
                />
              </div>
            </>
          )}

          {/* Left corner buttons */}
          <div style={cornerButtonsTopStyle(theme)}>
            <div style={cornerButtonsTopLeftStyle}>

              <button 
                style={overlayButtonStyle(theme, 'left', hoverStates.nodeText)}
                onClick={() => setNodeTextMode((prev) => 
                  prev === NODE_TEXT_MODES.SHOW_ALL 
                    ? NODE_TEXT_MODES.HIDE_ALL 
                    : NODE_TEXT_MODES.SHOW_ALL
                )}
                onMouseEnter={() => setHoverStates(prev => ({ ...prev, nodeText: true }))}
                onMouseLeave={() => setHoverStates(prev => ({ ...prev, nodeText: false }))}
              >
                <FontAwesomeIcon 
                  icon={faFont} 
                  style={{ fontSize: UNIT }}
                />
              </button>
              {(currentScaleHasAccidentals() || isTuningMode) && 
               nodeTextMode !== NODE_TEXT_MODES.HIDE_ALL && 
               nodeDisplayMode !== 'intervals' && (
                <button 
                  style={overlayButtonStyle(theme, 'left', hoverStates.accidental)}
                  onClick={cycleAccidentalStyle}
                  onMouseEnter={() => setHoverStates(prev => ({ ...prev, accidental: true }))}
                  onMouseLeave={() => setHoverStates(prev => ({ ...prev, accidental: false }))}
                >
                  <span style={{ fontSize: UNIT }}>
                    {accidentalStyle === 'sharp' ? '♯' : '♭'}
                  </span>
                </button>
              )}
            </div>
            <div style={cornerButtonsTopRightStyle}>
              <button 
                style={overlayButtonStyle(theme, 'right', hoverStates.rotate)} 
                onClick={toggleOrientation}
                onMouseEnter={() => setHoverStates(prev => ({ ...prev, rotate: true }))}
                onMouseLeave={() => setHoverStates(prev => ({ ...prev, rotate: false }))}
              >
                <FontAwesomeIcon 
                  icon={isVertical ? faRotateLeft : faRotateRight} 
                  style={{ fontSize: UNIT }}
                />
              </button>
              <button 
                style={overlayButtonStyle(theme, 'right', hoverStates.theme)} 
                onClick={toggleTheme}
                onMouseEnter={() => setHoverStates(prev => ({ ...prev, theme: true }))}
                onMouseLeave={() => setHoverStates(prev => ({ ...prev, theme: false }))}
              >
                <FontAwesomeIcon 
                  icon={isDarkMode ? faSun : faMoon} 
                  style={{ fontSize: UNIT }}
                />
              </button>
            </div>
          </div>

          <FretboardRenderer 
            svgRef={svgRef}
            titleAreaRef={titleAreaRef}
            unit={UNIT}
            strings={strings} 
            showNodes={showNodes} 
            height={contentHeight}
            isDarkMode={isDarkMode}
            stringSizeBase={stringSizeBase}
            isInfiniteFrets={isInfiniteFrets}
            showInlays={showInlays}
            onInlayToggle={(show) => setShowInlays(show)}
            isVertical={isVertical}
            fretDensity={fretDensity}
            onFretDensityChange={handleFretDensityChange}
            isInverted={isInverted}
            tuning={currentTuning}
            useFlats={accidentalStyle === 'flat'}
            accidentalStyle={accidentalStyle}
            onTuningChange={handleTuningChange}
            showBounds={showBounds}
            selectedKey={selectedKey}
            selectedScale={selectedScale}
            noteColors={noteColors}
            scaleRoot={scaleRoot}
            rootIndicator={rootIndicator}
            colorMode={colorMode}
            currentPreset={currentPreset}
            showNodeText={showNodeText}
            nodeTextMode={nodeTextMode}
            scaleDisplayMode={scaleDisplayMode}
            clickMode={clickMode}
            onPlayNote={soundPlayer.playNote}
            nodeDisplayMode={nodeDisplayMode}
            onNodeDisplayModeChange={setNodeDisplayMode}
            onNodeClick={(note, stringIndex, fretNumber, mode) => {
              console.log('Node clicked in App:', note, mode);
            }}
            setScaleRoot={setScaleRoot}
            isAudioInitialized={isAudioInitialized}
            isTuningMode={isTuningMode}
            activeTuningString={activeTuningString}
            setActiveTuningString={setActiveTuningString}
            PADDING={PADDING}
            hiddenNodes={hiddenNodes}
            setHiddenNodes={setHiddenNodes}
            selectedStrings={selectedStrings}
            setSelectedStrings={setSelectedStrings}
            selectedFrets={selectedFrets}
            setSelectedFrets={setSelectedFrets}
            />
        </div>

        {/* Fullscreen padding bottom */}
          <div style={{
            backgroundColor: theme.diagramBg,
            height: 0,
        }}
        />

        <div className="panelSection" style={panelSectionStyle(theme)
        }>
          <div 
            style={{
              ...resizerStyle(theme, isDragging),
              top: 0,
              left: 0,
              right: 0,
              transform: 'translateY(-100%)',
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          />
          <div ref={controlPanelRef} style={controlPanelStyle(theme, panelSize)}>
            <div ref={controlPanelContentRef} style={controlPanelContentStyle}>
              {renderControlPanelContent()}

            </div>
            <div style={cornerButtonsBottomLeftStyle(theme)}>
              <button 
                style={{
                  ...shareButtonStyle(theme, hoverStates.edit),
                  backgroundColor: clickMode === 'edit' ? theme.buttonBg : 'transparent',
                }}
                onClick={() => {
                  const newMode = clickMode === 'edit' ? 'play' : 'edit';
                  handlePanelChange(newMode);
                }}
                onMouseEnter={() => setHoverStates(prev => ({ ...prev, edit: true }))}
                onMouseLeave={() => setHoverStates(prev => ({ ...prev, edit: false }))}
              >
                <FontAwesomeIcon 
                  icon={faPencil}
                  style={{ 
                    fontSize: UNIT,
                    color: clickMode === 'edit' ? theme.buttonColor : theme.buttonColor,
                  }}
                />
              </button>
            </div>

            <div style={cornerButtonsBottomRightStyle(theme)}>
              <button 
                style={shareButtonStyle(theme, hoverStates.export)}
                onClick={handleShareClick}
                onMouseEnter={() => setHoverStates(prev => ({ ...prev, export: true }))}
                onMouseLeave={() => setHoverStates(prev => ({ ...prev, export: false }))}
              >
                <FontAwesomeIcon 
                  icon={faArrowUpFromSquare} 
                  style={{ fontSize: UNIT }}
                />
              </button>
            </div>
            
            <div style={logoContainerStyle}>
              <div 
                style={hanchnetLogoStyle} 
                onClick={handleLogoClick}
              >
                <HanchnetLogo />
              </div>
              {/* <div style={notesLogoStyle}>
                <NotesLogo />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {showShareModal && (
        <div 
          style={modalOverlayStyle}
          onClick={() => setShowShareModal(false)}
        >
          <div 
            style={modalContentStyle(theme)}
            onClick={(e) => e.stopPropagation()}
          >
            <div style={{
              fontSize: `${UNIT * 1.2}px`,
              fontWeight: 'bold',
              color: theme.textColor,
              marginBottom: UNIT,
              textAlign: 'center',
              width: '100%',
              borderBottom: `1px solid ${theme.buttonBg}`,
              paddingBottom: UNIT / 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: UNIT / 2
            }}>
              Share
              <FontAwesomeIcon 
                icon={faArrowUpFromSquare} 
                style={{ fontSize: `${UNIT}px` }}
              />
            </div>
            <button 
              style={modalButtonStyle(theme)}
              onClick={() => {
                copyCurrentUrl(setNotificationMessage);
                setShowShareModal(false);
              }}
            >
              <FontAwesomeIcon 
                icon={faLink} 
                style={{ fontSize: `${UNIT * 0.8}px` }} 
              />
              <span>Copy Link</span>
            </button>
            <button 
              style={modalButtonStyle(theme)}
              onClick={async () => {
                try {
                  await exportAsPNG(svgRef.current, 'fretboard.png');
                  setNotificationMessage('PNG export successful');
                } catch (error) {
                  console.error('Export failed:', error);
                  setNotificationMessage(`Export failed: ${error.message}`);
                }
                setShowShareModal(false);
              }}
            >
              <FontAwesomeIcon 
                icon={faFileExport} 
                style={{ fontSize: `${UNIT * 0.8}px` }} 
              />
              <span>Save as PNG</span>
            </button>
            <button 
              style={modalButtonStyle(theme)}
              onClick={async () => {
                try {
                  await exportAsSVG(svgRef.current);
                  setNotificationMessage('SVG export successful');
                } catch (error) {
                  console.error('Export failed:', error);
                  setNotificationMessage(`Export failed: ${error.message}`);
                }
                setShowShareModal(false);
              }}
            >
              <FontAwesomeIcon 
                icon={faFileCode} 
                style={{ fontSize: `${UNIT * 0.8}px` }} 
              />
              <span>Save as SVG</span>
            </button>
          </div>
        </div>
      )}
    <div style={{
      ...notificationStyle(theme),
      opacity: notificationVisible ? 1 : 0
    }}>
      {notificationMessage}
    </div>
    </div>
  );
};

export default App;
